<template>
  <div class="uploadCard">
    <!-- Snackbar -->
    <v-snackbar v-model="upload_successfulN" :timeout="3000">
      Sikeres feltöltés!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="upload_successfulN = false"
        >
          Ok
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="upload_failedN" :timeout="3000">
      Sikertelen feltöltés :(
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="upload_failedN = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="email_send_successfulN" :timeout="3000">
      E-mail küldés sikeres!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="email_send_successfulN = false"
        >
          Ok
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="email_send_failedN" :timeout="3000">
      E-mail küldés sikertelen :(
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="email_send_failedN = false"
        >
          Ok
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Overlay -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-card :loading="loading" class="mx-auto my-12" flat max-width="1000">
      <template slot="progress">
        <v-progress-linear
          color="deep-blue"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <div class="center" v-if="temporaryImage">
        <ul v-for="(temporaryImageSrc, index) in temporaryImage" :key="index">
          <img class="preview" :src="temporaryImageSrc" />
          <br />
        </ul>
      </div>

      <v-container class="uploadContainer mt-n12">
        <div class="form3">
          <v-col cols="12" sm="3" md="12">
            <v-form ref="form">
              <v-select
                :items="users"
                class="vmenu"
                label="Telepítő"
                solo
                @change="(item) => (user = item)"
                :menu-props="{ auto: true }"
                v-if="$store.state.isAdmin"
              >
                <template v-slot:item="{ item }">
                  <span class="d-flex justify-center" style="width: 100%">
                    {{ item.name }}
                  </span>
                </template>
                <template v-slot:selection="{ item }">
                  <span class="d-flex justify-center" style="width: 100%">
                    {{ item.name }}
                  </span>
                </template>
              </v-select>
              <v-text-field
                v-model="ugyfel_nevN"
                :background-color="
                  ugyfel_nevN == 'NINCS ADAT' || !ugyfel_nevN
                    ? 'red lighten-2'
                    : 'green lighten-2'
                "
                v-mask="
                  'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
                "
                label="Ügyfél neve"
                autofocus
                solo
              ></v-text-field>
              <!-- <v-text-field v-model="ugyfel_nev2N" v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'" label="Ügyfél neve 2. sor" solo></v-text-field> -->
              <v-text-field
                v-model="ugyfel_emailN"
                :background-color="
                  ugyfel_emailN == 'NINCS ADAT' || !ugyfel_emailN
                    ? 'red lighten-2'
                    : 'green lighten-2'
                "
                v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
                label="Ügyfél e-mail címe"
                solo
              ></v-text-field>
              <!-- <v-text-field v-model="ugyfel_email2N" v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXX'" label="Ügyfél e-mail címe 2. sor" solo></v-text-field> -->
              <v-text-field
                v-model="ugyfel_telefonN"
                :background-color="
                  ugyfel_telefonN == 'NINCS ADAT' || !ugyfel_telefonN
                    ? 'red lighten-2'
                    : 'green lighten-2'
                "
                v-mask="'XXXXXXXXXXXXXXXX'"
                label="Ügyfél telefonszáma"
                solo
              ></v-text-field>
              <v-text-field
                v-model="objektum_cimN"
                :background-color="
                  objektum_cimN == 'NINCS ADAT' || !objektum_cimN
                    ? 'red lighten-2'
                    : 'green lighten-2'
                "
                v-mask="
                  'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
                "
                label="Objektum címe"
                solo
              ></v-text-field>
              <!-- <v-text-field v-model="objektum_cim2N" v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'" label="Objektum címe 2. sor" solo></v-text-field> -->
              <!-- <v-text-field v-model="objektum_azonositoN" label="Objektum azonosítója" v-mask="'##-####'" @change="objektumFormat" solo></v-text-field> -->
              <v-row>
                <v-col class="d-flex align-center justify-center" cols="3">
                  <v-text-field
                    v-model="objektum_azonosito1N"
                    label="Vonalszám"
                    v-mask="'##'"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <h6 class="d-flex align-center justify-center mt-1">-</h6>
                </v-col>
                <v-col class="d-flex align-center justify-center">
                  <v-text-field
                    v-model="objektum_azonosito2N"
                    label="Ügyfél azonosító"
                    v-mask="'#####'"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex align-start justify-start pt-5">
                  <v-btn @click.prevent="getUserData">
                    <v-icon> mdi-download </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-mask="'####-##-##'"
                    solo
                    v-model="completeDateN"
                    label="Munka befejezés dátuma"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    @click.prevent="formatData"
                    :background-color="
                      completeDateN == '0000-00-00' || !completeDateN
                        ? 'red lighten-2'
                        : 'green lighten-2'
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="completeDateN"
                  min="2022-01-01"
                  :max="todaysDate"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
              <v-select
                :items="$store.state.telepito_ceg"
                class="vmenu"
                label="Telepítő cég"
                :background-color="
                  telepito_cegN == '00000000' || !telepito_cegN
                    ? 'red lighten-2'
                    : 'green lighten-2'
                "
                solo
                @change="(item) => (telepito_cegN = item)"
                :menu-props="{ auto: true }"
              >
                <template v-slot:selection="{ item }">
                  <span class="d-flex justify-center" style="width: 100%">
                    {{ item }}
                  </span>
                </template>
              </v-select>
              <v-text-field
                v-model="e_iroda_ugyszam"
                label="E-iroda ügyszám"
                v-mask="'XXXXXXXXXXX'"
                solo
              ></v-text-field>
              <v-row class="mt-n6">
                <v-col cols="10">
                  <v-text-field
                    v-model="szamlazasi_nevN"
                    :background-color="
                      szamlazasi_nevN == 'NINCS ADAT' || !szamlazasi_nevN
                        ? 'red lighten-2'
                        : 'green lighten-2'
                    "
                    v-mask="
                      'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
                    "
                    label="Számlázási név"
                    solo
                  ></v-text-field>
                  <!-- <v-text-field v-model="szamlazasi_nev2N" v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'" label="Számlázási név 2. sor" solo></v-text-field> -->
                </v-col>
                <v-col class="d-flex mt-3 justify-end" cols="2">
                  <v-btn small @click.prevent="copyName">
                    <v-icon> mdi-content-copy </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-n6">
                <v-col cols="10">
                  <v-text-field
                    v-model="szamlazasi_cimN"
                    :background-color="
                      szamlazasi_cimN == 'NINCS ADAT' || !szamlazasi_cimN
                        ? 'red lighten-2'
                        : 'green lighten-2'
                    "
                    v-mask="
                      'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
                    "
                    label="Számlázási cím"
                    solo
                  ></v-text-field>
                  <!-- <v-text-field v-model="szamlazasi_cim2N" v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'" label="Számlázási cím 2. sor" solo></v-text-field> -->
                </v-col>
                <v-col class="d-flex mt-3 justify-end" cols="2">
                  <v-btn small @click.prevent="copyAddress">
                    <v-icon> mdi-content-copy </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-text-field
                v-model="adoszamN"
                label="Adószám"
                v-mask="'########-#-##'"
                solo
              />
              <v-select
                :items="$store.state.fizetes_tipusok"
                label="Fizetés típusa"
                :background-color="
                  !fizetesN ? 'red lighten-2' : 'green lighten-2'
                "
                solo
                @change="(item) => paytype(item)"
                class="categoryStyled"
              >
                <template v-slot:selection="{ item }">
                  <span class="d-flex justify-center" style="width: 100%">
                    {{ item }}
                  </span>
                </template>
              </v-select>
              <v-file-input
                @change="onFileChange"
                class="my-2"
                multiple
                solo
                dense
                show-size
                counter
                label="Csatolmányok(MAX 10MB)"
              />
              <template v-for="(job, index) in jobs">
                <UploadJobCard
                  :jobID="job.jobID"
                  :index="index"
                  @updateJob="updateJob"
                  @deleteJob="deleteJob"
                  :archive="'false'"
                />
              </template>
              <template v-for="(product, index) in products">
                <UploadProductCard
                  :prodID="index"
                  @updateProd="updateProd"
                  @deleteProd="deleteProd"
                  :archive="'false'"
                />
              </template>
              <v-row align="center" justify="center">
                <v-btn
                  class="mb-4 d-flex"
                  @click="addJob"
                  :disabled="disabledJob"
                >
                  Munka hozzáadása
                </v-btn>
              </v-row>
              <v-row align="center" justify="center">
                <v-btn
                  class="mb-7 d-flex"
                  @click="addProduct"
                  :disabled="disabledProduct"
                >
                  Anyag hozzáadása
                </v-btn>
              </v-row>
              <v-select
                :items="$store.state.szamla_kuldes_tipusok"
                label="Számla küldés típusa"
                solo
                :background-color="
                  !szamla_kuldes_tipusaN ? 'red lighten-2' : 'green lighten-2'
                "
                @change="(item) => (szamla_kuldes_tipusaN = item)"
                class="categoryStyled"
              >
                <template v-slot:selection="{ item }">
                  <span class="d-flex justify-center" style="width: 100%">
                    {{ item }}
                  </span>
                </template>
              </v-select>
              <v-textarea
                rows="1"
                solo
                label="Megjegyzés"
                auto-grow
                ref="megjegyzes"
                v-model="megjegyzesN"
                :rules="[
                  (v) =>
                    (v || '').length <= 740 ||
                    'A megyjegyzés maximum 740 karakter lehet',
                ]"
              ></v-textarea>
              <v-row align="center" justify="center">
                <h2 class="my-6">
                  Összesen: {{ fizetendo_osszegN + fizetendo_osszegPN }} HUF
                </h2>
              </v-row>
            </v-form>
          </v-col>
        </div>
      </v-container>

      <v-divider class="mx-4"></v-divider>

      <v-card-text>
        <div class="center">
          <v-row align="center" justify="center" class="my-1">
            <p v-if="feedback" class="red-text">{{ feedback }}</p>
          </v-row>
          <v-row align="center" justify="center" class="my-1">
            <v-btn
              v-ripple="'rgba(200, 10, 10, 0.2)'"
              bottom
              color="transparent"
              elevation="5"
              @click.prevent="onUpload2"
              >Feltöltés</v-btn
            >
          </v-row>
          <v-row align="center" justify="center">
            <v-btn
              v-ripple="'rgba(200, 10, 10, 0.2)'"
              bottom
              color="transparent"
              elevation="5"
              @click.prevent="onUpload3"
              >Piszkozat mentése</v-btn
            >
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "firebase";
import db from "@/firebase/init";
const { uuid } = require("uuidv4");
import { Money } from "v-money";
import UploadJobCard from "@/components/home/UploadJobCard";
import UploadProductCard from "@/components/home/UploadProductCard";
import axios from "axios";

export default {
  name: "Upload",

  data() {
    return {
      user: { user_id: "" },
      uploaderNameN: null,
      uploaderEmailN: null,
      uploadValue: 0,
      compressedImage: [],
      compressionFinished: false,
      temporaryImage: [],
      firestoreUrl: [],
      multiplier: 1.0,
      feedback: null,
      loading: null,
      inputFilesN: [],
      jobs: [],
      jobsFirestore: "",
      jobsFilled: false,
      products: [],
      productsFirestore: "",
      productsFilled: true,
      ugyfel_nevN: null,
      ugyfel_nev2N: null,
      ugyfel_emailN: null,
      ugyfel_email2N: null,
      ugyfel_telefonN: null,
      telepito_cegN: null,
      e_iroda_ugyszam: null,
      objektum_cimN: null,
      objektum_cim2N: null,
      objektum_azonosito1N: null,
      objektum_azonosito2N: null,
      completeDateN: null,
      // munkalap_azonositoN: null,
      firestore_idN: null,
      szamlazasi_nevN: null,
      szamlazasi_nev2N: null,
      szamlazasi_cimN: null,
      szamlazasi_cim2N: null,
      szerelo_telefonN: null,
      adoszamN: null,
      szamla_kuldes_tipusaN: null,
      fizetesN: null,
      fizetendo_osszegN: 0,
      fizetendo_osszegPN: 0,
      megjegyzesN: "",
      upload_successfulN: false,
      upload_failedN: false,
      email_send_successfulN: false,
      email_send_failedN: false,
      disabledJob: false,
      disabledProduct: false,
      todaysDate: "",
      mailId: null,
      menu1: false,
      files: [],
      users: [],
      createdTimeN: 0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: " HUF",
        precision: 0,
        masked: false,
      },
      overlay: false,
      rimiLogoUrl: [
        "https://firebasestorage.googleapis.com/v0/b/e-munkalap-f65fe.appspot.com/o/rimi2.png?alt=media&token=28dd966f-53cf-4015-911b-29609498ae1d",
      ],
      adoszamRule: [
        (v) => !!v || "Adószám kötelező",
        (v) => (v && v.length > 12) || "Az adószám túl rövid",
      ],
      basicRule1: [(v) => !!v || "Vonalszám kötelező"],
      basicRule2: [(v) => !!v || "Ügyfélszám kötelező"],
    };
  },

  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 10000);
    },
    files(val) {
      console.log(val);
    },
  },

  components: {
    Money,
    UploadJobCard,
    UploadProductCard,
  },
  methods: {
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.files.push({ path: e.target.result });
      };
      reader.readAsDataURL(file);
    },
    onFileChange(file) {
      this.files = [];
      if (!file) {
        return;
      }
      file.forEach((_file) => {
        this.createImage(_file);
      });
    },

    getLogoUrl() {
      switch (this.telepito_cegN) {
        case "R.I.M.I. Magyarország Kft.":
          return "https://storage.googleapis.com/rimi_e_munkalap_public/R.I.M.I._logo.png";
        case "Rimi Monitoring Kft.":
          return "https://storage.googleapis.com/rimi_e_munkalap_public/RIMIlogo_0.png";
        case "SNI Hungary Kft.":
          return "https://storage.googleapis.com/rimi_e_munkalap_public/SNI_logo.png";
        default:
          // You can set a default logo URL here if needed
          return "https://storage.googleapis.com/rimi_e_munkalap_public/RIMIlogo_0.png";
      }
    },

    getUserData() {
      axios
        .get(
          "https://microus.rimimonitoring.com:48926/bitto.php?uid=hd7Us73loA87mc6+dk)swqP&line=" +
            this.objektum_azonosito1N +
            "&account=" +
            this.objektum_azonosito2N
        )
        .then((response) => {
          // axios.get('http://192.168.1.120:8112/bitto.php?uid=hd7Us73loA87mc6+dk)swqP&line=' + this.objektum_azonosito1N + '&account=' + this.objektum_azonosito2N).then((response)=>{

          if (response.data[0].name1) {
            this.ugyfel_nevN = response.data[0].name1.trim();
          }
          if (response.data[0].name2) {
            this.ugyfel_nevN =
              this.ugyfel_nevN + ", " + response.data[0].name2.trim();
          }
          if (response.data[0].email) {
            this.ugyfel_emailN = response.data[0].email.trim();
          }
          if (response.data[0].objtel) {
            this.ugyfel_telefonN = response.data[0].objtel.trim();
          }
          if (response.data[0].address3) {
            if (response.data[0].address3.charAt(0) == "0") {
              this.objektum_cimN = response.data[0].address3
                .substring(1)
                .trim();
            } else {
              this.objektum_cimN = response.data[0].address3.trim();
            }
          }
          if (response.data[0].address1) {
            this.objektum_cimN =
              this.objektum_cimN + " " + response.data[0].address1.trim();
          }
          if (response.data[0].address2) {
            this.objektum_cimN =
              this.objektum_cimN + " " + response.data[0].address2.trim();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    paytype(data) {
      this.fizetesN = data;
      if (data == "Átutalás") {
        this.multiplier = 1.02;
      } else if (data == "Készpénz") {
        this.multiplier = 1.0;
      } else {
        this.multiplier == 1.0;
      }
    },
    objektumFormat() {
      if (this.objektum_azonositoN.length != 7 && this.objektum_azonositoN) {
        let zeros = "";
        for (let x = 0; x < 7 - this.objektum_azonositoN.length; x++) {
          zeros = zeros + "0";
        }
        this.objektum_azonositoN =
          zeros + this.objektum_azonositoN.replace("-", "");
        this.objektum_azonositoN =
          this.objektum_azonositoN.charAt(0) +
          this.objektum_azonositoN.charAt(1) +
          "-" +
          this.objektum_azonositoN.charAt(2) +
          this.objektum_azonositoN.charAt(3) +
          this.objektum_azonositoN.charAt(4) +
          this.objektum_azonositoN.charAt(5) +
          this.objektum_azonositoN.charAt(6);
      } else if (this.objektum_azonositoN.length == 7) {
        this.objektum_azonositoN =
          this.objektum_azonositoN.charAt(0) +
          this.objektum_azonositoN.charAt(1) +
          "-" +
          this.objektum_azonositoN.charAt(2) +
          this.objektum_azonositoN.charAt(3) +
          this.objektum_azonositoN.charAt(4) +
          this.objektum_azonositoN.charAt(5) +
          this.objektum_azonositoN.charAt(6);
      }
    },
    copyName() {
      this.szamlazasi_nevN = this.ugyfel_nevN;
      this.szamlazasi_nev2N = this.ugyfel_nev2N;
    },
    copyAddress() {
      this.szamlazasi_cimN = this.objektum_cimN;
      this.szamlazasi_cim2N = this.objektum_cim2N;
    },
    onUpload2() {
      // Get current user data
      var get_phone_number = db
        .collection("users")
        .doc(firebase.auth().currentUser.email);
      get_phone_number.get().then((doc) => {
        this.szerelo_telefonN = doc.data().phone_number;

        this.jobsFilled = this.jobs.every((job) => {
          if (job.jobRef == "none") {
            return false;
          }
          if (job.jobType == "none") {
            return false;
          }
          if (job.jobPrice == "none") {
            return false;
          }
          return true;
        });

        this.productsFilled = this.products.every((product) => {
          if (product.productType == "none") {
            return false;
          }
          if (product.productNum == "none") {
            return false;
          }
          if (product.productPrice == "none") {
            return false;
          }
          return true;
        });
        if (
          this.telepito_cegN &&
          this.ugyfel_nevN &&
          this.ugyfel_emailN &&
          this.objektum_cimN &&
          this.fizetesN &&
          this.jobsFilled &&
          this.productsFilled &&
          this.szamla_kuldes_tipusaN &&
          this.szamlazasi_nevN &&
          this.szamlazasi_cimN &&
          this.ugyfel_telefonN &&
          this.jobs.length &&
          this.completeDateN != "0000-00-00" &&
          this.completeDateN
        ) {
          this.overlay = true;
          this.currentTime = new Date();
          let month = "";
          let day = "";
          let hours = "";
          let minutes = "";
          let seconds = "";
          if (this.currentTime.getMonth().toString().length + 1 > 1) {
            month = (parseInt(this.currentTime.getMonth()) + 1).toString();
          } else {
            month = "0" + parseInt(this.currentTime.getMonth() + 1).toString();
          }
          if (this.currentTime.getDate().toString().length > 1) {
            day = this.currentTime.getDate();
          } else {
            day = "0" + this.currentTime.getDate();
          }
          if (this.currentTime.getHours().toString().length > 1) {
            hours = this.currentTime.getHours();
          } else {
            hours = "0" + this.currentTime.getHours();
          }
          if (this.currentTime.getMinutes().toString().length > 1) {
            minutes = this.currentTime.getMinutes();
          } else {
            minutes = "0" + this.currentTime.getMinutes();
          }
          if (this.currentTime.getSeconds().toString().length > 1) {
            seconds = this.currentTime.getSeconds();
          } else {
            seconds = "0" + this.currentTime.getSeconds();
          }
          this.createdTimeN =
            this.currentTime.getFullYear() +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          if (this.telepito_cegN == "Rimi Monitoring Kft.") {
            var compID = "M";
          } else if (this.telepito_cegN == "R.I.M.I. Magyarország Kft.") {
            var compID = "R";
          } else if (this.telepito_cegN == "SNI Hungary Kft.") {
            var compID = "S";
          } else {
            var compID = "X";
          }
          this.firestore_idN =
            compID +
            this.currentTime.getFullYear().toString().substr(-2) +
            month +
            day +
            hours +
            minutes +
            seconds +
            this.szerelo_telefonN.substr(-2);
          this.firestoreUrl = [];
          var imageUUID = uuid();

          if (this.inputFilesN.length) {
            for (const data of this.compressedImage) {
              const storageRef = firebase
                .storage()
                .ref(`munkalapok/${imageUUID}`)
                .put(data);
              storageRef.on(
                `state_changed`,
                (snapshot) => {
                  this.uploadValue =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => {
                  console.log(error.message);
                },
                () => {
                  this.uploadValue = 100;
                  storageRef.snapshot.ref.getDownloadURL().then((url) => {
                    this.firestoreUrl.push(url);
                    if (this.firestoreUrl.length == this.inputFilesN.length) {
                      this.uploadToFirestore(this.firestoreUrl, imageUUID);
                    } else {
                    }
                  });
                }
              );
            }
          } else {
            this.uploadToFirestore(this.rimiLogoUrl, "0000");
          }
        } else {
          this.feedback = "Minden mező kitöltése kötelező!";
        }
      });
    },
    onUpload3() {
      // Get current user data
      var get_phone_number = db
        .collection("users")
        .doc(firebase.auth().currentUser.email);
      get_phone_number.get().then((doc) => {
        this.szerelo_telefonN = doc.data().phone_number;
        if (this.ugyfel_nevN && this.telepito_cegN) {
          this.overlay = true;
          this.currentTime = new Date();
          let month = "";
          let day = "";
          let hours = "";
          let minutes = "";
          let seconds = "";
          if (this.currentTime.getMonth().toString().length + 1 > 1) {
            month = (parseInt(this.currentTime.getMonth()) + 1).toString();
          } else {
            month = "0" + parseInt(this.currentTime.getMonth() + 1).toString();
          }
          if (this.currentTime.getDate().toString().length > 1) {
            day = this.currentTime.getDate();
          } else {
            day = "0" + this.currentTime.getDate();
          }
          if (this.currentTime.getHours().toString().length > 1) {
            hours = this.currentTime.getHours();
          } else {
            hours = "0" + this.currentTime.getHours();
          }
          if (this.currentTime.getMinutes().toString().length > 1) {
            minutes = this.currentTime.getMinutes();
          } else {
            minutes = "0" + this.currentTime.getMinutes();
          }
          if (this.currentTime.getSeconds().toString().length > 1) {
            seconds = this.currentTime.getSeconds();
          } else {
            seconds = "0" + this.currentTime.getSeconds();
          }
          this.createdTimeN =
            this.currentTime.getFullYear() +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          if (this.telepito_cegN == "Rimi Monitoring Kft.") {
            var compID = "M";
          } else if (this.telepito_cegN == "R.I.M.I. Magyarország Kft.") {
            var compID = "R";
          } else if (this.telepito_cegN == "SNI Hungary Kft.") {
            var compID = "S";
          } else {
            var compID = "X";
          }

          this.firestore_idN =
            compID +
            this.currentTime.getFullYear().toString().substr(-2) +
            month +
            day +
            hours +
            minutes +
            seconds +
            this.szerelo_telefonN.substr(-2);
          this.firestoreUrl = [];
          var imageUUID = uuid();

          if (this.inputFilesN.length) {
            for (const data of this.compressedImage) {
              const storageRef = firebase
                .storage()
                .ref(`munkalapok/${imageUUID}`)
                .put(data);
              storageRef.on(
                `state_changed`,
                (snapshot) => {
                  this.uploadValue =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => {
                  console.log(error.message);
                },
                () => {
                  this.uploadValue = 100;
                  storageRef.snapshot.ref.getDownloadURL().then((url) => {
                    this.firestoreUrl.push(url);
                    if (this.firestoreUrl.length == this.inputFilesN.length) {
                      this.uploadToFirestore2(this.firestoreUrl, imageUUID);
                    } else {
                    }
                  });
                }
              );
            }
          } else {
            this.uploadToFirestore2(this.rimiLogoUrl, "0000");
          }
        } else {
          this.feedback =
            "Az ügyfél neve és a telepítő cég kitöltése kötelező!";
        }
      });
    },
    addJob() {
      if (this.jobs.length > 8) {
        this.disabledJob = true;
      }
      this.jobs.push({
        jobID: this.jobs.length,
        jobRef: "none",
        jobType: "none",
        jobPrice: 0,
        key: uuid(),
      });
    },
    addProduct() {
      if (this.products.length > 8) {
        this.disabledProduct = true;
      }
      this.products.push({
        productID: this.products.length,
        productType: "none",
        productNum: "none",
        productPrice: 0,
        key: uuid(),
      });
    },
    updateJob({ jobID, key, data }) {
      if (key == "jobRef") {
        this.jobs[jobID].jobRef = data;
      } else if (key == "jobType") {
        this.jobs[jobID].jobType = data;
      } else if (key == "jobPrice") {
        this.jobs[jobID].jobPrice = data;
      }
      this.fizetendo_osszegN = 0;
      this.jobsFirestore = "";
      this.jobs.forEach((job, index) => {
        if (!isNaN(job.jobPrice)) {
          this.fizetendo_osszegN = this.fizetendo_osszegN + job.jobPrice;
        }
        if (index == 0) {
          var jobRef = job.jobRef.replace(/,/g, "ß");
          jobRef = jobRef.replace(/;/g, "ł");
          var jobType = job.jobType.replace(/,/g, "ß");
          jobType = jobType.replace(/;/g, "ł");
          this.jobsFirestore =
            job.jobID + "," + jobRef + "," + jobType + "," + job.jobPrice;
        } else {
          var jobRef = job.jobRef.replace(/,/g, "ß");
          jobRef = jobRef.replace(/;/g, "ł");
          var jobType = job.jobType.replace(/,/g, "ß");
          jobType = jobType.replace(/;/g, "ł");
          this.jobsFirestore =
            this.jobsFirestore +
            ";" +
            job.jobID +
            "," +
            jobRef +
            "," +
            jobType +
            "," +
            job.jobPrice;
        }
      });
      this.jobsFilled = this.jobs.every((job) => {
        if (job.jobRef == "none") {
          return false;
        }
        if (job.jobType == "none") {
          return false;
        }
        if (job.jobPrice == "none") {
          return false;
        }
        return true;
      });
    },
    updateProd({ prodID, key, data }) {
      if (key == "productType") {
        this.products[prodID].productType = data;
      } else if (key == "productNum") {
        this.products[prodID].productNum = data;
      } else if (key == "productPrice") {
        this.products[prodID].productPrice = data;
      }
      this.fizetendo_osszegPN = 0;
      this.productsFirestore = "";
      this.products.forEach((product, index) => {
        if (!isNaN(product.productPrice)) {
          this.fizetendo_osszegPN =
            this.fizetendo_osszegPN + product.productPrice * product.productNum;
        }
        if (index == 0) {
          var productType = product.productType.replace(/,/g, "ß");
          productType = productType.replace(/;/g, "ł");
          this.productsFirestore =
            product.productID +
            "," +
            productType +
            "," +
            product.productNum +
            "," +
            product.productPrice;
        } else {
          var productType = product.productType.replace(/,/g, "ß");
          productType = productType.replace(/;/g, "ł");
          this.productsFirestore =
            this.productsFirestore +
            ";" +
            product.productID +
            "," +
            productType +
            "," +
            product.productNum +
            "," +
            product.productPrice;
        }
      });
      this.productsFilled = this.products.every((product) => {
        if (product.productType == "none") {
          return false;
        }
        if (product.productNum == "none") {
          return false;
        }
        if (product.productPrice == "none") {
          return false;
        }
        return true;
      });
    },
    deleteProd(id) {
      if (this.disabledProduct) {
        this.disabledProduct = false;
      }
      this.products.splice(id, 1);
      this.fizetendo_osszegPN = 0;
      this.productsFirestore = "";
      this.products.forEach((product, index) => {
        if (product.productPrice != "none") {
          this.fizetendo_osszegPN =
            this.fizetendo_osszegPN + product.productPrice * product.productNum;
        }
        if (index == 0) {
          this.productsFirestore =
            product.productID +
            "," +
            product.productType +
            "," +
            product.productNum +
            "," +
            product.productPrice;
        } else {
          this.productsFirestore =
            this.productsFirestore +
            ";" +
            product.productID +
            "," +
            product.productType +
            "," +
            product.productNum +
            "," +
            product.productPrice;
        }
      });
    },
    deleteJob(id) {
      if (this.disabledJob) {
        this.disabledJob = false;
      }
      this.jobs.splice(id, 1);
      this.fizetendo_osszegN = 0;
      this.jobsFirestore = "";
      this.jobs.forEach((job, index) => {
        if (job.jobPrice != "none") {
          this.fizetendo_osszegN = this.fizetendo_osszegN + job.jobPrice;
        }
        if (index == 0) {
          this.jobsFirestore =
            job.jobID +
            "," +
            job.jobRef +
            "," +
            job.jobType +
            "," +
            job.jobPrice;
        } else {
          this.jobsFirestore =
            this.jobsFirestore +
            ";" +
            job.jobID +
            "," +
            job.jobRef +
            "," +
            job.jobType +
            "," +
            job.jobPrice;
        }
      });
    },

    uploadToFirestore(uploadUrl, imageId) {
      // Generate random uuid
      var authToken = uuid();

      if (!this.adoszamN) {
        this.adoszamN = "00000000000";
      }

      // Get current user data
      var get_phone_number = db
        .collection("users")
        .doc(firebase.auth().currentUser.email);
      get_phone_number.get().then((doc) => {
        this.szerelo_telefonN = doc.data().phone_number;

        if (!this.e_iroda_ugyszam) {
          this.e_iroda_ugyszam = "00000000";
        }

        if (!this.objektum_azonosito1N) {
          this.objektum_azonosito1N = "00";
        }
        if (!this.objektum_azonosito2N) {
          this.objektum_azonosito2N = "0000";
        }

        // Check if Admin and user is set
        if (this.$store.state.isAdmin && this.user) {
          this.szerelo_telefonN = this.user.phone_number;
          this.uploaderNameN = this.user.name;
          this.uploaderEmailN = this.user.email;
        } else {
          this.uploaderNameN = this.$store.state.username;
          this.uploaderEmailN = firebase.auth().currentUser.email;
          this.user.user_id = firebase.auth().currentUser.uid;
        }

        //Upload data to Firestore
        db.collection("munkalapok")
          .add({
            createdTime: this.createdTimeN,
            ugyfel_nev: this.ugyfel_nevN,
            ugyfel_email: this.ugyfel_emailN,
            ugyfel_telefon: this.ugyfel_telefonN,
            telepito_ceg: this.telepito_cegN,
            szerelo_telefon: this.szerelo_telefonN,
            objektum_cim: this.objektum_cimN,
            objektum_azonosito:
              this.objektum_azonosito1N + "-" + this.objektum_azonosito2N,
            szamlazasi_nev: this.szamlazasi_nevN,
            szamlazasi_cim: this.szamlazasi_cimN,
            adoszam: this.adoszamN,
            e_iroda_ugyszam: this.e_iroda_ugyszam,
            szamla_kuldes_tipusa: this.szamla_kuldes_tipusaN,
            fizetes: this.fizetesN,
            fizetendo_osszegM: this.fizetendo_osszegN,
            fizetendo_osszegA: this.fizetendo_osszegPN,
            megjegyzes: this.megjegyzesN.substr(0, 740),
            // uploaderId: firebase.auth().currentUser.uid,
            uploaderId: this.user.user_id,
            firestore_id: this.firestore_idN,
            // uploaderName: this.$store.state.username,
            // uploaderEmail: firebase.auth().currentUser.email,
            uploaderName: this.uploaderNameN,
            uploaderEmail: this.uploaderEmailN,
            munkak: this.jobsFirestore,
            anyagok: this.productsFirestore,
            completeDate: this.completeDateN,
            dirty: "false",
            archive: "false",
            imageId: imageId,
            url: uploadUrl,
            authToken: authToken,
            approved: "pending",
            email_failed: "false",
          })
          .catch((err) => {
            this.feedback = err;
            this.upload_failedN = true;
            console.log(err);
          })
          .then((workDocRef) => {
            //Send notification to the center
            this.upload_successfulN = true;
            // Get send email
            var get_email = db.collection("config").doc("email");
            get_email.get().then((doc) => {
              var send_email = doc.data().to;

              db.collection("mail")
                .add({
                  to: send_email,
                  message: {
                    subject: "Új munkalap feltöltve",
                    text:
                      this.$store.state.username +
                      " új munkalapot töltött fel. \n\nAz ügyfél neve: " +
                      this.ugyfel_nevN +
                      "\nDokumentum azonosító: " +
                      this.firestore_idN,
                    attachments: this.files,
                  },
                })
                .catch((err) => {
                  setTimeout(() => {
                    (this.feedback = err), (this.email_send_failedN = true);
                  }, 4000),
                    this.$refs.form.reset(),
                    (this.fizetendo_osszegN = 0),
                    (this.fizetendo_osszegPN = 0),
                    (this.firestoreUrl = []),
                    (this.temporaryImage = {}),
                    (this.compressedImage = []),
                    (this.inputFilesN = []);
                  this.overlay = false;
                })
                .then((adminMailDocRef) => {
                  //Send notification to the customer
                  db.collection("mail")
                    .add({
                      to: this.ugyfel_emailN,
                      message: {
                        subject:
                          "A " +
                          this.telepito_cegN +
                          " új munkalapot állított ki Önnek!",
                        html: `
        <!DOCTYPE html>
        <html lang="hu">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Új Munkalap Értesítés</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              background-color: white;
              margin: 0;
              padding: 0;
            }
            .container {
              max-width: 600px;
              margin: 0 auto;
              padding: 20px;
            }
            .header {
              text-align: center;
              margin-bottom: 20px;
            }
            .logo {
              max-width: 100px;
              margin-bottom: 10px;
            }
            h1 {
              color: #333;
              font-size: 24px;
            }
            .content {
              text-align: center;
              margin-bottom: 30px;
            }
            .button {
              display: inline-block;
              background-color: #007bff;
              color: white;
              text-decoration: none;
              padding: 10px 20px;
              border-radius: 5px;
              font-weight: bold;
            }
            .footer {
              font-size: 12px;
              color: #666;
              text-align: center;
              margin-top: 30px;
            }
          </style>
        </head>
        <body>
          <div class="container">
            <div class="header">
              <img src="${this.getLogoUrl()}" alt="Logo" class="logo">
            </div>
            <div class="content">
              <h2>Tisztelt ${this.ugyfel_nevN}!</h2>
              <p>A(z) ${this.telepito_cegN} új munkalapot állított ki Önnek!</p>
              <p>
                <a href="https://munkalap.rimimonitoring.com/approve?id=${workDocRef.id.toString()}${authToken}" class="button">Munkalap megtekintése</a>
              </p>
            </div>
            <div class="footer">
              <p>Ez egy automatikusan generált e-mail, kérjük ne válaszoljon rá. Kérdéseit, észrevételeit kérjük küldje a rimi@rimi.hu címre.</p>
            </div>
          </div>
        </body>
        </html>
      `,
                        text: `Tisztelt ${this.ugyfel_nevN}!
A(z) ${this.telepito_cegN} új munkalapot állított ki Önnek!

A munkalapot az alábbi linken tudja megtekinteni és elfogadni: https://munkalap.rimimonitoring.com/approve?id=${workDocRef.id.toString()}${authToken}

Ez egy automatikusan generált e-mail, kérjük ne válaszoljon rá. Kérdéseit, észrevételeit kérjük küldje a rimi@rimi.hu címre.`,
                      },
                    })
                    .then((mailDocRef) => {
                      this.mailId = mailDocRef.id.toString();
                      this.$router.push({ name: "HomePage" });
                    });
                  // setTimeout((mailDocRef) => {this.email_send_successfulN = true}, 4000),this.$refs.form.reset(),
                  // this.fizetendo_osszegN = 0,
                  // this.fizetendo_osszegPN = 0,
                  // this.firestoreUrl = [],
                  // this.temporaryImage = {},
                  // this.compressedImage = [],
                  // this.inputFilesN = [],
                  // this.jobs = [],
                  // this.products = [],
                  // this.jobsFirestore = null,
                  // this.productsFirestore = null,
                  // this.overlay = false
                  // this.$router.push({name: 'HomePage'})
                });
            });
          });
      });
    },

    uploadToFirestore2(uploadUrl, imageId) {
      // Generate random uuid
      var authToken = uuid();

      // Get current user data
      var get_phone_number = db
        .collection("users")
        .doc(firebase.auth().currentUser.email);
      get_phone_number.get().then((doc) => {
        this.szerelo_telefonN = doc.data().phone_number;

        if (!this.ugyfel_nevN) {
          this.ugyfel_nevN = "NINCS ADAT";
        }
        if (!this.adoszamN) {
          this.adoszamN = "00000000000";
        }
        if (!this.ugyfel_emailN) {
          this.ugyfel_emailN = "NINCS ADAT";
        }
        if (!this.objektum_cimN) {
          this.objektum_cimN = "NINCS ADAT";
        }
        if (!this.objektum_azonosito1N) {
          this.objektum_azonosito1N = "00";
        }
        if (!this.objektum_azonosito2N) {
          this.objektum_azonosito2N = "0000";
        }
        if (!this.fizetesN) {
          this.fizetesN = "";
        }
        if (!this.szamla_kuldes_tipusaN) {
          this.szamla_kuldes_tipusaN = "";
        }
        if (!this.szamlazasi_nevN) {
          this.szamlazasi_nevN = "NINCS ADAT";
        }
        if (!this.szamlazasi_cimN) {
          this.szamlazasi_cimN = "NINCS ADAT";
        }
        if (!this.ugyfel_telefonN) {
          this.ugyfel_telefonN = "NINCS ADAT";
        }
        if (!this.e_iroda_ugyszam) {
          this.e_iroda_ugyszam = "00000000";
        }
        if (!this.jobsFirestore.length) {
          this.jobsFirestore = "0,NINCS ADAT,NINCS ADAT,NINCS ADAT";
        }
        this.completeDateN = "0000-00-00";

        // Check if Admin and user is set
        if (this.$store.state.isAdmin && this.user) {
          this.szerelo_telefonN = this.user.phone_number;
          this.uploaderNameN = this.user.name;
          this.uploaderEmailN = this.user.email;
        } else {
          this.uploaderNameN = this.$store.state.username;
          this.uploaderEmailN = firebase.auth().currentUser.email;
          this.user.user_id = firebase.auth().currentUser.uid;
        }
        //Upload data to Firestore
        db.collection("munkalapok")
          .add({
            createdTime: this.createdTimeN,
            ugyfel_nev: this.ugyfel_nevN,
            ugyfel_email: this.ugyfel_emailN,
            ugyfel_telefon: this.ugyfel_telefonN,
            telepito_ceg: this.telepito_cegN,
            szerelo_telefon: this.szerelo_telefonN,
            objektum_cim: this.objektum_cimN,
            objektum_azonosito:
              this.objektum_azonosito1N + "-" + this.objektum_azonosito2N,
            szamlazasi_nev: this.szamlazasi_nevN,
            szamlazasi_cim: this.szamlazasi_cimN,
            adoszam: this.adoszamN,
            e_iroda_ugyszam: this.e_iroda_ugyszam,
            szamla_kuldes_tipusa: this.szamla_kuldes_tipusaN,
            fizetes: this.fizetesN,
            fizetendo_osszegM: this.fizetendo_osszegN,
            fizetendo_osszegA: this.fizetendo_osszegPN,
            megjegyzes: this.megjegyzesN.substr(0, 740),
            // uploaderId: firebase.auth().currentUser.uid,
            uploaderId: this.user.user_id,
            firestore_id: this.firestore_idN,
            // uploaderName: this.$store.state.username,
            // uploaderEmail: firebase.auth().currentUser.email,
            uploaderName: this.uploaderNameN,
            uploaderEmail: this.uploaderEmailN,
            munkak: this.jobsFirestore,
            anyagok: this.productsFirestore,
            completeDate: this.completeDateN,
            imageId: imageId,
            url: uploadUrl,
            authToken: authToken,
            dirty: "true",
            archive: "false",
            approved: "pending",
            email_failed: "false",
          })
          .catch((err) => {
            this.feedback = err;
            this.upload_failedN = true;
            console.log(err);
          })
          .then((workDocRef) => {
            //Send notification to the center
            this.upload_successfulN = true;
            this.$router.push({ name: "HomePage" });
          });
      });
    },
    formatData() {
      this.completeDateN = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
    },

    compress() {
      if (this.inputFilesN != null) {
        this.compressionFinished = false;
        this.temporaryImage = [];
        this.compressedImage = [];
        this.uploadValue = 0;
        let width = 500;
        let height;
        const elem = document.createElement("canvas");
        for (const data of this.inputFilesN) {
          const fileName = data.name;
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            (img.onload = () => {
              if (img.width > width) {
                const ratio = img.height / img.width;
                height = width * ratio;
              } else {
                width = img.width;
                height = img.height;
              }
              elem.width = width;
              elem.height = height;
              const ctx = elem.getContext("2d");
              ctx.drawImage(img, 0, 0, width, height);
              this.temporaryImage.push(elem.toDataURL("image/jpeg", 0.5));
              ctx.canvas.toBlob(
                (blob) => {
                  this.compressedImage.push(
                    new File([blob], fileName, {
                      type: "image/jpg",
                      lastModified: Date.now(),
                    })
                  );
                },
                "image/jpeg",
                1
              );
            }),
              (reader.onerror = (error) => console.log(error));
          };
        }
        this.compressionFinished = true;
      } else console.log("Data is empty, pass");
    },
  },

  created() {
    this.currentTime = new Date();
    var month = (this.currentTime.getMonth() + 1).toString();
    if (month.length < 2) {
      month = "0" + month;
    }
    var day = this.currentTime.getDate().toString();
    if (day.length < 2) {
      day = "0" + day;
    }
    this.todaysDate = this.currentTime.getFullYear() + "-" + month + "-" + day;

    //fetch data from the firestore
    db.collection("users")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          let user = doc.data();
          user.email = doc.id;
          this.users.push(user);
        });
      });
  },
};
</script>

<style>
img.preview {
  width: 200px;
}
.uploadCard .uploadContainer .form1 {
  max-width: 600px;
  margin: 0px auto;
  padding: 0px 20px;
}
.uploadCard .uploadContainer .form2 {
  max-width: 600px;
  margin: 0px auto;
  padding: 0px 20px;
}
span {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
