var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card-actions',[_c('v-row',{staticClass:"my-1",attrs:{"justify":"space-around","align":"center"}},[_c('v-col',{staticClass:"my-n4 mr-n8",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"align-center my-1 mr-2",attrs:{"color":"blue","fab":"","dark":"","small":"","width":"30","height":"30"},on:{"click":function($event){$event.preventDefault();return _vm.viewPDF.apply(null, arguments)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"align-center my-1",attrs:{"color":"blue","fab":"","dark":"","small":"","width":"30","height":"30"},on:{"click":function($event){$event.preventDefault();return _vm.downloadPDF.apply(null, arguments)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-download")])],1)],1),_c('v-col',{staticClass:"mb-n4 mr-n8",attrs:{"cols":"12"}},[(_vm.archive == 'false' || _vm.$store.state.isAdmin)?_c('v-btn',{staticClass:"align-center my-1 mr-2",attrs:{"color":"blue","fab":"","dark":"","small":"","width":"30","height":"30"},on:{"click":function($event){$event.preventDefault();return _vm.editCard.apply(null, arguments)}}},[_c('v-icon',{attrs:{"dense":"","size":"20"}},[_vm._v("mdi-pencil")])],1):_vm._e(),(
          _vm.$store.state.user != null &&
          _vm.$store.state.isAdmin &&
          _vm.archive == 'false'
        )?_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-center my-1 mr-2",attrs:{"color":"red","dark":"","fab":"","small":"","width":"30","height":"30"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-archive")])],1)]}}],null,false,348456835),model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline break_long_text"},[_vm._v(" Biztosan archiválni szeretnéd ezt a munkalapot? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:('rgba(200, 10, 10, 0.2)'),expression:"'rgba(200, 10, 10, 0.2)'"}],attrs:{"color":"transparent"},on:{"click":function($event){$event.preventDefault();return _vm.deleteItem.apply(null, arguments)}}},[_vm._v(" Igen ")]),_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:('rgba(200, 10, 10, 0.2)'),expression:"'rgba(200, 10, 10, 0.2)'"}],attrs:{"color":"transparent"},on:{"click":function($event){$event.preventDefault();_vm.deleteDialog = false}}},[_vm._v(" Nem ")])],1)],1)],1):_vm._e(),(
          _vm.$store.state.user != null &&
          _vm.$store.state.isAdmin &&
          _vm.$route.name != 'HomePage'
        )?_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-center my-1",attrs:{"color":"red","dark":"","fab":"","small":"","width":"30","height":"30"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-delete")])],1)]}}],null,false,3958165336),model:{value:(_vm.deleteDialog2),callback:function ($$v) {_vm.deleteDialog2=$$v},expression:"deleteDialog2"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline break_long_text"},[_vm._v(" Biztosan törölni szeretnéd ezt a munkalapot? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:('rgba(200, 10, 10, 0.2)'),expression:"'rgba(200, 10, 10, 0.2)'"}],attrs:{"color":"transparent"},on:{"click":function($event){$event.preventDefault();return _vm.deleteItem2.apply(null, arguments)}}},[_vm._v(" Igen ")]),_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:('rgba(200, 10, 10, 0.2)'),expression:"'rgba(200, 10, 10, 0.2)'"}],attrs:{"color":"transparent"},on:{"click":function($event){$event.preventDefault();_vm.deleteDialog2 = false}}},[_vm._v(" Nem ")])],1)],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }