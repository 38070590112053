<template>
    <div class="approveCard">
        <v-row align="center" justify="center">
            <v-col class="d-flex align-center justify-center">
                <v-card class="mt-12" max-width="400px" flat>
                    <v-row v-if="tokenFound == '1'" align="center" justify="center" class="my-2">
                        <v-card class="mx-7 mt-12"  elevation="3">
                            <v-row align="center" justify="center">
                                <v-col>
                                    <v-card flat @click.prevent="viewPDF('view')">
                                      <v-row align="center" justify="center">
                                        <v-col class="d-flex align-center justify-center">
                                          <v-img v-bind:src=url height="200" max-width="200" class="align-center d-flex justify-center">
                                            <template v-slot:placeholder>
                                                <v-row class="fill-height" align="center" justify="center">
                                                    <v-progress-circular indeterminate color="blue"></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-card-title class="justify-center">{{ugyfel_nev}}</v-card-title>
                            <v-card-text align="center">
                                <div>Feltöltő: {{uploader_name}}</div>
                                <div>{{time}}</div>
                            </v-card-text>
                            <v-divider v-if="$store.state.isAdmin == true" class="mx-4"></v-divider>
                            <v-row align="center" justify="center" class="my-2">
                                <v-btn color="blue" fab small @click.prevent="viewPDF('download')">
                                    <v-icon color="white">
                                        mdi-download
                                    </v-icon>
                                </v-btn>
                            </v-row>
                        </v-card>
                        <v-row align="center" justify="center" class="mt-4 mb-4">
                        <!-- Job accept button -->
                        <v-dialog v-model="acceptDialog" persistent max-width="500" v-if="approved == 'pending'">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="green" dark v-bind="attrs" v-on="on" class="mr-4">
                                    Elfogadás
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="headline break_long_text">
                                    Biztosan elfogadja a munkalapot?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="transparent" v-ripple="'rgba(200, 10, 10, 0.2)'" @click.prevent="accept">
                                        Igen
                                    </v-btn>
                                    <v-btn color="transparent" @click.prevent="acceptDialog = false" v-ripple="'rgba(200, 10, 10, 0.2)'">
                                        Nem
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <!-- Job denied button -->
                        <v-dialog v-model="denyDialog" persistent max-width="500" v-if="approved == 'pending'">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="red" dark v-bind="attrs" v-on="on">
                                    Elutasítás
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="headline break_long_text">
                                    Biztosan elutasítja a munkalapot?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-text-field label="Indoklás" v-model="deny_reason" class="mx-4 mt-5" solo></v-text-field>
                                    <v-btn color="transparent" v-ripple="'rgba(200, 10, 10, 0.2)'" @click.prevent="deny">
                                        Igen
                                    </v-btn>
                                    <v-btn color="transparent" @click.prevent="denyDialog = false" v-ripple="'rgba(200, 10, 10, 0.2)'">
                                        Nem
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                            <!-- <v-btn color="green" v-if="approved == 'pending'" v-ripple @click.prevent="accept" class="mr-4 white--text">Elfogadás</v-btn>
                            <v-btn color="red" v-if="approved == 'pending'" class="white--text" v-ripple @click.prevent="deny">Elutasítás</v-btn> -->
                            <v-btn color="green" v-if="approved == 'accepted'" v-ripple class="mr-4 white--text">Elfogadva</v-btn>
                            <v-btn color="red" v-if="approved == 'accepted'" class="white--text" v-ripple disabled >Elutasítás</v-btn>
                            <v-btn color="green" v-if="approved == 'denied'" v-ripple disabled class="mr-4 white--text">Elfogadás</v-btn>
                            <v-btn color="red" v-if="approved == 'denied'" class="white--text" v-ripple >Elutasítva</v-btn>
                        </v-row>   
                    </v-row>
                    <h2 v-else-if="tokenFound == '2' " align="center" justify="center" class="blue--text mt-n2 pb-8 pt-3">Érvénytelen token</h2>
                    <h2 v-else-if="tokenFound == '0' " align="center" class="blue--text mt-n2 pb-8 pt-3">Token ellenőrzés...</h2>
                    <!-- <div v-if="!reCaptcha" align="center" justify="center" class="mt-4 mb-2 mx-2" id="recaptcha"></div> -->
                </v-card>  
            </v-col>
        </v-row>
    </div>
</template>

<script>
import firebase from 'firebase'
import db from '@/firebase/init'
import jsPDF from "jspdf"
import ItemCardButtom from '@/components/home/ItemCardButton'
import { isMobile } from 'mobile-device-detect'



export default {
    name: 'AcceptJob',
    components: {
        ItemCardButtom,
    },
    beforeMount(){
        window.addEventListener("beforeunload", this.logout)
    },
    beforeDestroy(){
            firebase.auth().signOut().then(() => {
              this.$store.commit("setUser", null)
              this.$store.commit("setAdminState", false)
              this.$router.push({ name: 'Login' })    
            })
    },
    

  data(){
	return{
        id: this.$route.query.id.slice(0,this.$route.query.id.length-36),
        isMobile: isMobile,
        authToken : this.$route.query.id.slice(-36),
        item: null,
        url: null,
        imageid: null,
        firestore_id: "000000000000000",
        ugyfel_nev: null,
        ugyfel_nev2: null,
        ugyfel_telefon: " ",
        telepito_ceg: null,
        time: null,
        uploader_id: null,
        uploader_name: null,
        szamlazasi_nev: null,
        szamlazasi_nev2: null,
        szamlazasi_cim: null,
        szamlazasi_cim2: null,
        szerelo_telefon: null,
        szamla_kuldes_tipusa: null,
        adoszam: null,
        fizetendo_osszegM: null,
        fizetendo_osszegA: null,
        fizetes: null,
        multiplier: 1.00,
        hivatkozas: null,
        megjegyzes: null,
        munka_tipusa: null,
        objektum_azonosito: null,
        objektum_cim: null,
        objektum_cim2: null,
        ugyfel_email: null,
        ugyfel_email2: null,
        uploader_email: null,
        uploader_phone_number: null,
        munkak: null,
        completeDate: "0000-00-00",
        anyagok: null,
        tokenFound: "0",
        reCaptcha: true,
        approved: "",
        acceptDialog: false,
        denyDialog: false,
        deny_reason: null,
    }
  },
  methods: {
      logout(){
           firebase.auth().signOut().then(() => {
              this.$store.commit("setUser", null)
              this.$store.commit("setAdminState", false)    
            })
      },
          hexToString (hex) {
    var string = '';
    for (var i = 0; i < hex.length; i += 2) {
      string += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    }
    return string;
      },
      viewPDF(viewType) {
      // Generate Image
      var img = new Image();
      this.munkadij = 0;
      this.anyagdij = 0;

      // Get status
      var get_status = db.collection("munkalapok").doc(this.id);
      get_status.get().then((doc) => {
        var _status = doc.data().approved;
        var _dirty = doc.data().dirty;

        //Get logo
        img.src = this.$store.getters.getImage("rimilogo");
        img.onload = () => {
          //Generate new doc
          var doc = new jsPDF({
            lineHeightProportion: 2,
            filters: ["ASCIIHexEncode"],
          });
          doc.setFont("courier", "normal");
          // add custom font to file
          doc.addImage(img, "PNG", 5, 5, 20, 20);
          doc.setFontSize(13);
          doc.text(this.telepito_ceg, 30, 13);
          doc.setFontSize(10);
          doc.text("1133 Budapest, Gogol u. 26.", 30, 18);
          doc.setFontSize(28);

          //Dátum
          doc.setFontSize(10);
          if (this.completeDate) {
            doc.text(
              "Telj:" + this.completeDate.replace("-", "/").replace("-", "/"),
              165,
              10
            );
          } else {
            doc.text(
              "Telj:" + this.time.replace("-", "/").replace("-", "/"),
              165,
              10
            );
          }

          //Get center helpers
          doc.setFontSize(19);
          var textWidth =
            (doc.getStringUnitWidth("E-Munkalap") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset, 30, "E-Munkalap");

          //Munkalap azonosító és objektum azonosító
          doc.setFont("courier","bold");
          doc.setFontSize(10);
          // var textWidth = doc.getStringUnitWidth(this.firestore_id) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          // var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(165, 15, this.firestore_id);
          doc.setFont("courier","normal");
          doc.setFontSize(14);
          doc.setFont("courier","bold");
          doc.setTextColor(255, 0, 0);
          var textWidth =
            (doc.getStringUnitWidth(this.objektum_azonosito) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset, 38, this.objektum_azonosito);
          doc.setTextColor(0, 0, 0);

          //Ügyfélszám placeholder
          doc.setFontSize(11);
          doc.setFont("courier","bold");
          doc.text(165, 25, "Ügyszám: ");
          doc.text(183, 25, this.e_iroda_ugyszam);

          // Státusz
          doc.setFontSize(10);
          doc.text(165, 20, "Státusz: ");
          doc.setDrawColor(0);
          if (_dirty == "true") {
            doc.setFont("courier","bold");
            doc.text(182, 20, "P");
            doc.setFont("courier","normal");
            doc.setFillColor(250, 200, 0);
          } else {
            if (_status == "pending") {
              doc.setFont("courier","bold");
              doc.text(182, 20, "N");
              doc.setFont("courier","normal");
              doc.setFillColor(151, 151, 151);
            } else if (_status == "accepted") {
              doc.setFont("courier","bold");
              doc.text(182, 20, "E");
              doc.setFont("courier","normal");
              doc.setFillColor(0, 255, 0);
            } else if (_status == "denied") {
              doc.setFont("courier","bold");
              doc.text(182, 20, "V");
              doc.setFont("courier","normal");
              doc.setFillColor(255, 0, 0);
            }
          }

          doc.rect(186, 16, 10, 5, "FD");

          //Megrendelő email
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(
              "Megrendel" + this.hexToString("f4") + " e-mail"
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset + 16,
            50,
            "Megrendel" + this.hexToString("f4") + " e-mail"
          );

          //Megrendelő email placeholder
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(this.ugyfel_email.substring(0, 25)) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 16, 55, this.ugyfel_email.substring(0, 25));
          if (this.ugyfel_email.length > 25) {
            var textWidth =
              (doc.getStringUnitWidth(this.ugyfel_email.substring(25)) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(textOffset + 16, 60, this.ugyfel_email.substring(25));
          }

          //Megrendelő telefon
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(
              "Megrendel" + this.hexToString("f4") + " telefon"
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset + 16,
            70,
            "Megrendel" + this.hexToString("f4") + " telefon"
          );

          //Megrendelő telefon placeholder
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(this.ugyfel_telefon) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 16, 75, this.ugyfel_telefon);

          //Függőleges Vonal
          var lineOffset = (doc.internal.pageSize.getWidth() - 200) / 2;
          doc.line(150, 53, 150, 80);

          //Szerelő neve
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(
              "Szerel" + this.hexToString("f4") + " neve"
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset + 75,
            50,
            "Szerel" + this.hexToString("f4") + " neve"
          );

          //Szerelő neve placeholder
          //Find ő end replace it
          let corrector1 = "";
          for (var i = 0; i < this.uploader_name.length; i++) {
            if (this.uploader_name[i] == "ő") {
              corrector1 = corrector1 + this.hexToString("f4");
            } else if (this.uploader_name[i] == "Ő") {
              corrector1 = corrector1 + this.hexToString("d4");
            } else if (this.uploader_name[i] == "ű") {
              corrector1 = corrector1 + this.hexToString("fb");
            } else if (this.uploader_name[i] == "Ű") {
              corrector1 = corrector1 + this.hexToString("db");
            } else {
              corrector1 = corrector1 + this.uploader_name[i];
            }
          }

          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(corrector1) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 75, 55, corrector1);

          //Szerelő telefonszáma
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(
              "Szerel" + this.hexToString("f4") + " tel. száma"
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset + 75,
            70,
            "Szerel" + this.hexToString("f4") + " tel. száma"
          );

          //Szerelő telefonszáma placeholder
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(this.szerelo_telefon) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 75, 75, this.szerelo_telefon);

          //Megrendelő neve
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(
              "Megrendel" + this.hexToString("f4") + " neve"
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset - 59,
            50,
            "Megrendel" + this.hexToString("f4") + " neve"
          );

          //Megrendelő neve placeholder
          //Find ő end replace it
          let corrector2 = "";
          for (var i = 0; i < this.ugyfel_nev.length; i++) {
            if (this.ugyfel_nev[i] == "ő") {
              corrector2 = corrector2 + this.hexToString("f4");
            } else if (this.ugyfel_nev[i] == "Ő") {
              corrector2 = corrector2 + this.hexToString("d4");
            } else if (this.ugyfel_nev[i] == "ű") {
              corrector2 = corrector2 + this.hexToString("fb");
            } else if (this.ugyfel_nev[i] == "Ű") {
              corrector2 = corrector2 + this.hexToString("db");
            } else {
              corrector2 = corrector2 + this.ugyfel_nev[i];
            }
          }
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(corrector2.substring(0, 40)) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset - 59, 55, corrector2.substring(0, 40));
          if (corrector2.length > 40) {
            var textWidth =
              (doc.getStringUnitWidth(corrector2.substring(40)) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(textOffset - 59, 60, corrector2.substring(40));
          }

          //Megrendelő címe
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(
              "Megrendel" + this.hexToString("f4") + " címe"
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset - 59,
            70,
            "Megrendel" + this.hexToString("f4") + " címe"
          );

          //Megrendelő címe placeholder
          //Find ő end replace it
          let corrector3 = "";
          for (var i = 0; i < this.objektum_cim.length; i++) {
            if (this.objektum_cim[i] == "ő") {
              corrector3 = corrector3 + this.hexToString("f4");
            } else if (this.objektum_cim[i] == "Ő") {
              corrector3 = corrector3 + this.hexToString("d4");
            } else if (this.objektum_cim[i] == "ű") {
              corrector3 = corrector3 + this.hexToString("fb");
            } else if (this.objektum_cim[i] == "Ű") {
              corrector3 = corrector3 + this.hexToString("db");
            } else {
              corrector3 = corrector3 + this.objektum_cim[i];
            }
          }
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(corrector3.substring(0, 40)) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset - 59, 75, corrector3.substring(0, 40));
          if (corrector3.length > 40) {
            var textWidth =
              (doc.getStringUnitWidth(corrector3.substring(40)) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(textOffset - 59, 80, corrector3.substring(40));
          }

          //Vonal
          var lineOffset = (doc.internal.pageSize.getWidth() - 200) / 2;
          doc.line(lineOffset + 5, 82, 200, 82);

          //Elvégzett munkák
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Elvégzett munkák") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset - 75, 90, "Elvégzett munkák");

          //Munkadíjak
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Munkadíjak") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset - 30, 90, "Munkadíjak");

          //Függőleges Vonal
          var lineOffset = (doc.internal.pageSize.getWidth() - 200) / 2;
          doc.line(lineOffset + 97, 82, 102, 200);

          //Elvégzett munka lista
          doc.setFontSize(8);
          var index = 0;
          var local_munkak = this.munkak.split(";");
          for (const munka of local_munkak) {
            var data = munka.split(",");
            // Correct special characters
            data[1] = data[1].replace(/ß/g, ",");
            data[1] = data[1].replace(/ł/g, ";");
            data[2] = data[2].replace(/ß/g, ",");
            data[2] = data[2].replace(/ł/g, ";");
            //Find ő end replace it
            let corrector4 = "";
            for (var i = 0; i < data[2].length; i++) {
              if (data[2][i] == "ő") {
                corrector4 = corrector4 + this.hexToString("f4");
              } else if (data[2][i] == "Ő") {
                corrector4 = corrector4 + this.hexToString("d4");
              } else if (data[2][i] == "ű") {
                corrector4 = corrector4 + this.hexToString("fb");
              } else if (data[2][i] == "Ű") {
                corrector4 = corrector4 + this.hexToString("db");
              } else {
                corrector4 = corrector4 + data[2][i];
              }
            }
            var textWidth =
              (doc.getStringUnitWidth(corrector4.substring(0, 25)) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(
              textOffset - 75,
              100 + index * 10,
              corrector4.substring(0, 25)
            );
            var textWidth =
              (doc.getStringUnitWidth(
                new Intl.NumberFormat("hu-HU", {
                  style: "currency",
                  currency: "HUF",
                  maximumFractionDigits: 0,
                }).format(data[3])
              ) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(
              textOffset - 30,
              100 + index * 10,
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(data[3])
            );
            this.munkadij = this.munkadij + parseInt(data[3]);
            index++;
          }

          //Össz. munkadíj placeholder
          doc.setFontSize(11);
          var textWidth =
            (doc.getStringUnitWidth(
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(this.munkadij.toString())
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset - 30,
            205,
            new Intl.NumberFormat("hu-HU", {
              style: "currency",
              currency: "HUF",
              maximumFractionDigits: 0,
            }).format(this.munkadij.toString()) + " + ÁFA"
          );

          //Felhasznált anyagok
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Felhasznált anyagok") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 20, 90, "Felhasznált anyagok");

          //Felhasznált anyagok darab
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("db") * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 46, 90, "db");

          //Anyagdíj/db
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Ár/db") * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 63, 90, "Ár/db");

          //Összesen anyagdíj
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Anyagdíjak") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 85, 90, "Anyagdíjak");

          //Anyag lista
          doc.setFontSize(8);
          var index = 0;
          if (this.anyagok) {
            var local_anyagok = this.anyagok.split(";");
            for (const anyag of local_anyagok) {
              var data = anyag.split(",");
              // Correct special characters
              data[1] = data[1].replace(/ß/g, ",");
              data[1] = data[1].replace(/ł/g, ";");
              //Find ő end replace it
              let corrector5 = "";
              for (var i = 0; i < data[1].length; i++) {
                if (data[1][i] == "ő") {
                  corrector5 = corrector5 + this.hexToString("f4");
                } else if (data[1][i] == "Ő") {
                  corrector5 = corrector5 + this.hexToString("d4");
                } else if (data[1][i] == "ű") {
                  corrector5 = corrector5 + this.hexToString("fb");
                } else if (data[1][i] == "Ű") {
                  corrector5 = corrector5 + this.hexToString("db");
                } else {
                  corrector5 = corrector5 + data[1][i];
                }
              }
              var textWidth =
                (doc.getStringUnitWidth(corrector5.substring(0, 20)) *
                  doc.internal.getFontSize()) /
                doc.internal.scaleFactor;
              var textOffset =
                (doc.internal.pageSize.getWidth() - textWidth) / 2;
              doc.text(
                textOffset + 20,
                100 + index * 10,
                corrector5.substring(0, 20)
              );
              var textWidth =
                (doc.getStringUnitWidth(data[2]) * doc.internal.getFontSize()) /
                doc.internal.scaleFactor;
              var textOffset =
                (doc.internal.pageSize.getWidth() - textWidth) / 2;
              doc.text(textOffset + 46, 100 + index * 10, data[2]);
              var textWidth =
                (doc.getStringUnitWidth(
                  new Intl.NumberFormat("hu-HU", {
                    style: "currency",
                    currency: "HUF",
                    maximumFractionDigits: 0,
                  }).format(data[3])
                ) *
                  doc.internal.getFontSize()) /
                doc.internal.scaleFactor;
              var textOffset =
                (doc.internal.pageSize.getWidth() - textWidth) / 2;
              doc.text(
                textOffset + 63,
                100 + index * 10,
                new Intl.NumberFormat("hu-HU", {
                  style: "currency",
                  currency: "HUF",
                  maximumFractionDigits: 0,
                }).format(data[3])
              );
              var textWidth =
                (doc.getStringUnitWidth(
                  new Intl.NumberFormat("hu-HU", {
                    style: "currency",
                    currency: "HUF",
                    maximumFractionDigits: 0,
                  }).format(data[2] * data[3])
                ) *
                  doc.internal.getFontSize()) /
                doc.internal.scaleFactor;
              var textOffset =
                (doc.internal.pageSize.getWidth() - textWidth) / 2;
              if (data[2] * data[3] == "-0") {
                doc.text(
                  textOffset + 85,
                  100 + index * 10,
                  new Intl.NumberFormat("hu-HU", {
                    style: "currency",
                    currency: "HUF",
                    maximumFractionDigits: 0,
                  }).format("0")
                );
              } else {
                doc.text(
                  textOffset + 85,
                  100 + index * 10,
                  new Intl.NumberFormat("hu-HU", {
                    style: "currency",
                    currency: "HUF",
                    maximumFractionDigits: 0,
                  }).format(data[2] * data[3])
                );
              }
              this.anyagdij = this.anyagdij + parseInt(data[2] * data[3]);
              index++;
            }
          }

          //Össz. anyagdíj placeholder
          doc.setFontSize(11);
          var textWidth =
            (doc.getStringUnitWidth(
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(this.anyagdij.toString())
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset + 65,
            205,
            new Intl.NumberFormat("hu-HU", {
              style: "currency",
              currency: "HUF",
              maximumFractionDigits: 0,
            }).format(this.anyagdij.toString()) + " + ÁFA"
          );

          //Vonal
          var lineOffset = (doc.internal.pageSize.getWidth() - 200) / 2;
          doc.line(lineOffset + 5, 94, 200, 94);

          // Adminisztrációs költség 2%
          if (this.fizetes == "Átutalás") {
            doc.setFontSize(11);
            doc.setFont("courier","bold");
            var textWidth =
              (doc.getStringUnitWidth("Adminisztrációs költség 2%:") *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(textOffset + 42, 210, "Adminisztrációs költség 2%:");
            var textWidth =
              (doc.getStringUnitWidth(
                new Intl.NumberFormat("hu-HU", {
                  style: "currency",
                  currency: "HUF",
                  maximumFractionDigits: 0,
                }).format(
                  (
                    (this.munkadij + this.anyagdij) *
                    this.multiplier *
                    0.02
                  ).toString()
                )
              ) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(
              180,
              210,
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(
                (
                  (this.munkadij + this.anyagdij) *
                  this.multiplier *
                  0.02
                ).toString()
              )
            );
            doc.setFont("courier","normal");
            this.handling_fee =
              (this.munkadij + this.anyagdij) * this.multiplier * 0.02;
          }

          if (this.fizetes == "Átutalás") {
            //Össz. össz. nettó
            doc.setFontSize(11);
            doc.setFont("courier","bold");
            var textWidth =
              (doc.getStringUnitWidth("Összesen nettó:") *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(textOffset + 28, 215, "Összesen nettó:");

            //Össz. össz. nettó placeholder
            doc.setFontSize(11);
            doc.setFont("courier","bold");
            var textWidth =
              (doc.getStringUnitWidth(
                new Intl.NumberFormat("hu-HU", {
                  style: "currency",
                  currency: "HUF",
                  maximumFractionDigits: 0,
                }).format(
                  (
                    (this.munkadij + this.anyagdij + this.handling_fee) *
                    this.multiplier
                  ).toString()
                )
              ) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(
              180,
              215,
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(
                (
                  (this.munkadij + this.anyagdij + this.handling_fee) *
                  this.multiplier
                ).toString()
              )
            );
            doc.setFont("courier","normal");
          } else {
            //Össz. össz. nettó
            doc.setFontSize(11);
            doc.setFont("courier","bold");
            var textWidth =
              (doc.getStringUnitWidth("Összesen nettó:") *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(textOffset + 28, 215, "Összesen nettó:");

            //Össz. össz. nettó placeholder
            doc.setFontSize(11);
            doc.setFont("courier","bold");
            var textWidth =
              (doc.getStringUnitWidth(
                new Intl.NumberFormat("hu-HU", {
                  style: "currency",
                  currency: "HUF",
                  maximumFractionDigits: 0,
                }).format(
                  ((this.munkadij + this.anyagdij) * this.multiplier).toString()
                )
              ) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(
              180,
              215,
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(
                ((this.munkadij + this.anyagdij) * this.multiplier).toString()
              )
            );
            doc.setFont("courier","normal");
          }

          //Össz. össz. bruttó
          doc.setFontSize(11);
          doc.setFont("courier","bold");
          var textWidth =
            (doc.getStringUnitWidth("Összesen bruttó:") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 29, 220, "Összesen bruttó:");

          if (this.fizetes == "Átutalás") {
            //Össz. össz. bruttó placeholder
            doc.setFontSize(11);
            doc.setFont("courier","bold");
            var textWidth =
              (doc.getStringUnitWidth(
                new Intl.NumberFormat("hu-HU", {
                  style: "currency",
                  currency: "HUF",
                  maximumFractionDigits: 0,
                }).format(
                  (
                    ((this.munkadij + this.anyagdij) * this.multiplier +
                      (this.munkadij + this.anyagdij) *
                        this.multiplier *
                        0.02) *
                    1.27
                  ).toString()
                )
              ) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(
              180,
              220,
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(
                (
                  ((this.munkadij + this.anyagdij) * this.multiplier +
                    (this.munkadij + this.anyagdij) * this.multiplier * 0.02) *
                  1.27
                ).toString()
              )
            );
            doc.setFont("courier","normal");
          } else {
            //Össz. össz. bruttó placeholder
            doc.setFontSize(11);
            doc.setFont("courier","bold");
            var textWidth =
              (doc.getStringUnitWidth(
                new Intl.NumberFormat("hu-HU", {
                  style: "currency",
                  currency: "HUF",
                  maximumFractionDigits: 0,
                }).format(
                  (
                    (this.munkadij + this.anyagdij) *
                    this.multiplier *
                    1.27
                  ).toString()
                )
              ) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(
              180,
              220,
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(
                (
                  (this.munkadij + this.anyagdij) *
                  this.multiplier *
                  1.27
                ).toString()
              )
            );
            doc.setFont("courier","normal");
          }

          // Átutalás megjegyzés
          if (this.fizetes != "Készpénz") {
            doc.setFontSize(10);
            doc.text(
              15,
              225,
              "Készpénz fizetés esetén a 2% adminisztrációs költséget elengedjük."
            );
          }

          //Vonal
          var lineOffset = (doc.internal.pageSize.getWidth() - 200) / 2;
          doc.line(lineOffset + 5, 228, 200, 228);

          //Számla adószám
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Adószám:") * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(10, 235, "Adószám:");

          //Számla adószám placeholder
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(this.adoszam) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(28, 235, this.adoszam);

          //Számlaküldési mód
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Számlaküldés módja:") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(60, 235, "Számlaküldés módja:");

          //Számlaküldési mód placeholder
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(this.szamla_kuldes_tipusa) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(101, 235, this.szamla_kuldes_tipusa);

          //Fizetési mód
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Fizetési mód:") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(140, 235, "Fizetési mód:");

          //Fizetési mód placeholder
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(this.fizetes) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          if (this.fizetes == "Fix összegű utalás") {
            doc.text(
              168,
              235,
              "Fix összeg" + this.hexToString("fb") + " utalás"
            );
          } else {
            doc.text(168, 235, this.fizetes);
          }

          //Számlázási név
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Számlázási név:") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(10, 249, "Számlázási név:");

          //Számlázási név placeholder
          //Find ő end replace it
          let corrector6 = "";

          for (var i = 0; i < this.szamlazasi_nev.length; i++) {
            if (this.szamlazasi_nev[i] == "ő") {
              corrector6 = corrector6 + this.hexToString("f4");
            } else if (this.szamlazasi_nev[i] == "Ő") {
              corrector6 = corrector6 + this.hexToString("d4");
            } else if (this.szamlazasi_nev[i] == "ű") {
              corrector6 = corrector6 + this.hexToString("fb");
            } else if (this.szamlazasi_nev[i] == "Ű") {
              corrector6 = corrector6 + this.hexToString("db");
            } else {
              corrector6 = corrector6 + this.szamlazasi_nev[i];
            }
          }
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(corrector6.substring(0, 75)) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(42, 249, corrector6.substring(0, 75));
          // LONGER TEXT WILL BE CUT
          // if(corrector6.length > 75){
          //   var textWidth = doc.getStringUnitWidth(corrector6.substring(75)) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          //   var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          //   doc.text(42, 263, corrector6.substring(75));
          // }

          //Számlázási cím
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Számlázási cím:") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(10, 242, "Számlázási cím:");

          //Számlázási cím placeholder
          //Find ő end replace it

          let corrector7 = "";
          for (var i = 0; i < this.szamlazasi_cim.length; i++) {
            if (this.szamlazasi_cim[i] == "ő") {
              corrector7 = corrector7 + this.hexToString("f4");
            } else if (this.szamlazasi_cim[i] == "Ő") {
              corrector7 = corrector7 + this.hexToString("d4");
            } else if (this.szamlazasi_cim[i] == "ű") {
              corrector7 = corrector7 + this.hexToString("fb");
            } else if (this.szamlazasi_cim[i] == "Ű") {
              corrector7 = corrector7 + this.hexToString("db");
            } else {
              corrector7 = corrector7 + this.szamlazasi_cim[i];
            }
          }
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(corrector7.substring(0, 75)) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(42, 242, corrector7.substring(0, 75));
          // LONGER TEXT WILL BE CUT
          // if(corrector7.length > 75){
          //   var textWidth = doc.getStringUnitWidth(corrector7.substring(75)) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          //   var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          //   doc.text(42, 250, corrector7.substring(75));
          // }

          //Megjegyzés
          //Find ő end replace it
          let corrector8 = "";
          for (var i = 0; i < this.megjegyzes.length; i++) {
            if (this.megjegyzes[i] == "ő") {
              corrector8 = corrector8 + this.hexToString("f4");
            } else if (this.megjegyzes[i] == "Ő") {
              corrector8 = corrector8 + this.hexToString("d4");
            } else if (this.megjegyzes[i] == "ű") {
              corrector8 = corrector8 + this.hexToString("fb");
            } else if (this.megjegyzes[i] == "Ű") {
              corrector8 = corrector8 + this.hexToString("db");
            } else {
              corrector8 = corrector8 + this.megjegyzes[i];
            }
          }
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Megjegyzés: ") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(10, 256, "Megjegyzés: ");
          var textWidth =
            (doc.getStringUnitWidth(corrector8) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          var splitText = doc.splitTextToSize(
            corrector8.replace(/\r?\n|\r/gm, "").substring(80),
            179
          );
          doc.text(
            33,
            256,
            corrector8.replace(/\r?\n|\r/gm, "").substring(0, 80)
          );
          doc.text(10, 260, splitText);

          //Közlemények
          doc.setFontSize(9);
          doc.setFont("courier","bold");
          var textWidth =
            (doc.getStringUnitWidth(
              "A felhasznált anyagok a vételár teljes kiegyenlítéséig a kivitelez" +
                this.hexToString("f4") +
                " tulajdonát képezik"
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset,
            292,
            "A felhasznált anyagok a vételár teljes kiegyenlítéséig a kivitelez" +
              this.hexToString("f4") +
              " tulajdonát képezik"
          );
          doc.setFont("courier","normal");

          if (isMobile) {
            doc.save(this.ugyfel_nev + "_" + this.time.split(" ")[0] + ".pdf");
          } else if (!isMobile) {
            if (viewType == "view") {
              //Print new page
              var string = doc.output("dataurlstring");
              var iframe =
                "<iframe width='100%' height='100%' src='" +
                string +
                "'></iframe>";
              var x = window.open(string);
              x.document.open();
              x.document.write(iframe);
              x.document.close();
            } else if (viewType == "download") {
              doc.save(
                this.ugyfel_nev + "_" + this.time.split(" ")[0] + ".pdf"
              );
            }
          }
        };
      });
    },
      accept(){
          this.acceptDialog = false,
            //Upload data to Firestore
            db.collection('munkalapok').doc(this.id).update({
            approved: "accepted"
      }).catch(err => {
                console.log(err)
            }).then( ref => {
                // Get send email
                var get_email = db.collection('config').doc('email')
                get_email.get().then((doc) => {
                var send_email = doc.data().to
                this.approved = "accepted",
                db.collection('mail').add({
                    to: send_email,
                    message: {
                        subject: "Munkalap elfogadva",
                        text: this.ugyfel_nev + " elfogadta a munkalapot." + "\nDokumentum azonosító: " + this.id,
                        },
                        }).catch(err => {
                            // Handle error
                        })
            })
            }

            )},
        deny(){
            this.denyDialog = false,
            //Upload data to Firestore
            db.collection('munkalapok').doc(this.id).update({
            approved: "denied",
            deny_reason: this.deny_reason
      }).catch(err => {
                console.log(err)
            }).then( ref => {
              // Get send email
                var get_email = db.collection('config').doc('email')
                get_email.get().then((doc) => {
                var send_email = doc.data().to
                this.approved = "denied",
                db.collection('mail').add({
                    to: send_email,
                    message: {
                        subject: "Munkalap elutasítva",
                        text: this.ugyfel_nev + " elutasította a munkalapot." + "\nDokumentum azonosító: " + this.id + "\nIndoklás: " + this.deny_reason,
                        },
                        }).catch(err => {
                            // Handle error
                        })
            })
            })
          },
  },
    // watch: {
    //   reCaptcha() {
    //       if(this.reCaptcha){
    //             firebase.auth().signInWithEmailAndPassword("guest@rimi.hu", "6H{au??j!Fna,^rw").then( cred => {
    //             db.collection('munkalapok').get().then(snapshot => {
    //             snapshot.forEach(doc => {
    //             if(this.id == doc.id && this.authToken == doc.data().authToken){
    //             this.tokenFound = "1"
    //             var item = doc.data()
    //             item.id = doc.id
    //             this.id = item.id
    //             this.url = item.url[0]
    //             this.imageid = item.imageid
    //             this.ugyfel_nev = item.ugyfel_nev
    //             this.ugyfel_telefon = item.ugyfel_telefon
    //             this.e_iroda_ugyszam = item.e_iroda_ugyszam
    //             this.telepito_ceg = item.telepito_ceg
    //             this.time = item.createdTime
    //             this.uploader_id = item.uploaderId
    //             this.firestore_id = item.firestore_id
    //             this.uploader_name = item.uploaderName
    //             this.szamlazasi_nev = item.szamlazasi_nev
    //             this.szamlazasi_cim = item.szamlazasi_cim
    //             this.szerelo_telefon = item.szerelo_telefon
    //             this.szamla_kuldes_tipusa = item.szamla_kuldes_tipusa
    //             this.adoszam = item.adoszam
    //             this.fizetendo_osszegM = item.fizetendo_osszegM
    //             this.fizetendo_osszegA = item.fizetendo_osszegA
    //             this.fizetes = item.fizetes
    //             this.hivatkozas = item.hivatkozas
    //             this.megjegyzes = item.megjegyzes
    //             this.munka_tipusa = item.munka_tipus
    //             this.objektum_azonosito = item.objektum_azonosito
    //             this.objektum_cim = item.objektum_cim
    //             this.ugyfel_email = item.ugyfel_email
    //             this.uploader_email = item.uploaderEmail
    //             this.uploader_phone_number = item.phone_number
    //             this.munkak = item.munkak
    //             this.anyagok = item.anyagok
    //             this.approved = item.approved
    //             // Check multiplier
    //             if(this.fizetes == "Átutalás"){
    //               this.multiplier = 1.00
    //               }
    //             else if(this.fizetes == "Készpénz"){
    //               this.multiplier = 1.00
    //               }
    //             else {
    //               this.multiplier = 1.00
    //               }
    //             }
    //         })
    //         }).then(() => {
    //                 if(this.tokenFound != "1"){
    //                 this.tokenFound = "2"
    //             }
    //         }).catch((error) => {
    //             console.log(error)
    //             })
    //             })
    //       }
        
    //   },
    // },
  mounted(){
                firebase.auth().signInWithEmailAndPassword("guest@rimi.hu", "6H{au??j!Fna,^rw").then( cred => {
                db.collection('munkalapok').get().then(snapshot => {
                snapshot.forEach(doc => {
                if(this.id == doc.id && this.authToken == doc.data().authToken){
                this.tokenFound = "1"
                var item = doc.data()
                item.id = doc.id
                this.id = item.id
                this.url = item.url[0]
                this.imageid = item.imageid
                this.ugyfel_nev = item.ugyfel_nev
                this.ugyfel_nev2 = item.ugyfel_nev2
                this.ugyfel_telefon = item.ugyfel_telefon
                this.e_iroda_ugyszam = item.e_iroda_ugyszam
                this.telepito_ceg = item.telepito_ceg
                this.time = item.createdTime
                this.uploader_id = item.uploaderId
                this.firestore_id = item.firestore_id
                this.uploader_name = item.uploaderName
                this.szamlazasi_nev = item.szamlazasi_nev
                this.szamlazasi_nev2 = item.szamlazasi_nev2
                this.szamlazasi_cim = item.szamlazasi_cim
                this.szamlazasi_cim2 = item.szamlazasi_cim2
                this.szerelo_telefon = item.szerelo_telefon
                this.szamla_kuldes_tipusa = item.szamla_kuldes_tipusa
                this.adoszam = item.adoszam
                this.fizetendo_osszegM = item.fizetendo_osszegM
                this.fizetendo_osszegA = item.fizetendo_osszegA
                this.fizetes = item.fizetes
                this.hivatkozas = item.hivatkozas
                this.megjegyzes = item.megjegyzes
                this.munka_tipusa = item.munka_tipus
                this.objektum_azonosito = item.objektum_azonosito
                this.objektum_cim = item.objektum_cim
                this.objektum_cim2 = item.objektum_cim2
                this.ugyfel_email = item.ugyfel_email
                this.ugyfel_email2 = item.ugyfel_email2
                this.uploader_email = item.uploaderEmail
                this.uploader_phone_number = item.phone_number
                this.munkak = item.munkak
                this.anyagok = item.anyagok
                this.approved = item.approved
                this.completeDate = item.completeDate
                // Check multiplier
                if(this.fizetes == "Átutalás"){
                  this.multiplier = 1.00
                  }
                else if(this.fizetes == "Készpénz"){
                  this.multiplier = 1.00
                  }
                else {
                  this.multiplier = 1.00
                  }
                }
            })
            }).then(() => {
                    if(this.tokenFound != "1"){
                    this.tokenFound = "2"
                }
            }).catch((error) => {
                console.log(error)
                })
                })
          
    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
    //     'callback': (response) => {
    //     this.reCaptcha = true
    //     },
  // 'expired-callback': () => {
  //     this.reCaptcha = false
  // }
// })
  // window.recaptchaVerifier.render()

  },
}
</script>
<style>

</style>