<template>
  <v-card-actions>
    <v-row justify="space-around" align="center" class="my-1">
      <v-col cols="12" class="my-n4 mr-n8">
        <!-- View and Download buttons -->
        <v-btn
          color="blue"
          fab
          dark
          small
          class="align-center my-1 mr-2"
          @click.prevent="viewPDF"
          width="30"
          height="30"
        >
          <v-icon size="20">mdi-eye</v-icon>
        </v-btn>
        <v-btn
          color="blue"
          fab
          dark
          small
          class="align-center my-1"
          @click.prevent="downloadPDF"
          width="30"
          height="30"
        >
          <v-icon size="20">mdi-download</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="12" class="mb-n4 mr-n8">
        <!-- Edit button -->
        <v-btn
          color="blue"
          fab
          dark
          small
          class="align-center my-1 mr-2"
          @click.prevent="editCard"
          width="30"
          height="30"
          v-if="archive == 'false' || $store.state.isAdmin"
        >
          <v-icon dense size="20">mdi-pencil</v-icon>
        </v-btn>

        <!-- Admin archive and delete buttons -->
        <v-dialog
          v-model="deleteDialog"
          persistent
          max-width="500"
          v-if="
            $store.state.user != null &&
            $store.state.isAdmin &&
            archive == 'false'
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red"
              class="align-center my-1 mr-2"
              dark
              v-bind="attrs"
              v-on="on"
              fab
              small
              width="30"
              height="30"
            >
              <v-icon size="20">mdi-archive</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline break_long_text">
              Biztosan archiválni szeretnéd ezt a munkalapot?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="transparent"
                v-ripple="'rgba(200, 10, 10, 0.2)'"
                @click.prevent="deleteItem"
              >
                Igen
              </v-btn>
              <v-btn
                color="transparent"
                @click.prevent="deleteDialog = false"
                v-ripple="'rgba(200, 10, 10, 0.2)'"
              >
                Nem
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="deleteDialog2"
          persistent
          max-width="500"
          v-if="
            $store.state.user != null &&
            $store.state.isAdmin &&
            $route.name != 'HomePage'
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red"
              class="align-center my-1"
              dark
              v-bind="attrs"
              v-on="on"
              fab
              small
              width="30"
              height="30"
            >
              <v-icon size="20">mdi-delete</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline break_long_text">
              Biztosan törölni szeretnéd ezt a munkalapot?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="transparent"
                v-ripple="'rgba(200, 10, 10, 0.2)'"
                @click.prevent="deleteItem2"
              >
                Igen
              </v-btn>
              <v-btn
                color="transparent"
                @click.prevent="deleteDialog2 = false"
                v-ripple="'rgba(200, 10, 10, 0.2)'"
              >
                Nem
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-card-actions>
</template>

<script>
export default {
  name: "ItemCardButtom",
  props: [
    "url",
    "imageid",
    "ugyfel_nev",
    "ugyfel_nev2",
    "ugyfel_telefon",
    "szerelo_telefon",
    "id",
    "time",
    "e_iroda_ugyszam",
    "munkalap_azonosito",
    "uploader_id",
    "uploader_name",
    "szamlazasi_nev",
    "szamlazasi_nev2",
    "szamlazasi_cim",
    "szamlazasi_cim2",
    "szamla_kuldes_tipusa",
    "adoszam",
    "fizetendo_osszegM",
    "fizetendo_osszegA",
    "fizetes",
    "hivatkozas",
    "megjegyzes",
    "munka_tipusa",
    "objektum_azonosito",
    "objektum_cim",
    "objektum_cim2",
    "ugyfel_email",
    "ugyfel_email2",
    "uploader_email",
    "telepito_ceg",
    "munkak",
    "anyagok",
    "authToken",
    "firestore_id",
    "archive",
    "completeDate",
    "dirty",
  ],
  data() {
    return {
      width: 0,
      height: 0,
      orderDialog: null,
      favDialog: null,
      deleteDialog: null,
      deleteDialog2: null,
      favArray: [],
      reserveArray: [],
      reservedByOther: false,
      allReservesOld: [],
      allReserves: [],
      allReservesId: [],
      ref: null,
      // timeoutId: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const row = this.$el.querySelector(".mx-1");
      // this.$store.state.functionButtonsHeight = row.offsetHeight + 90;
      // this.$store.state.functionButtonsHeightMobile = row.offsetHeight + 80;
    });
  },
  methods: {
    // onResize() {
    //   clearTimeout(this.timeoutId);
    //   this.timeoutId = setTimeout(() => {
    //     const row = this.$el.querySelector('.mx-1');
    //   if (this.$store.state.functionButtonsHeight != row.offsetHeight + 60) {
    //     this.$store.state.functionButtonsHeight = row.offsetHeight + 60
    //   }
    //   }, 3000);

    // },
    deleteItem() {
      this.deleteDialog = false;
      this.$emit("deleteItem", { id: this.id, imageid: this.imageid });
    },
    deleteItem2() {
      this.deleteDialog2 = false;
      this.$emit("deleteItem2", { id: this.id, imageid: this.imageid });
    },
    editCard() {
      this.$router.push({
        name: "EditCard",
        params: {
          id: this.id,
          url: this.url,
          szerelo_telefon: this.szerelo_telefon,
          uploader_email: this.uploader_email,
          ugyfel_telefon: this.ugyfel_telefon,
          munkalap_azonosito: this.munkalap_azonosito,
          imageid: this.imageid,
          ugyfel_nev: this.ugyfel_nev,
          ugyfel_nev2: this.ugyfel_nev2,
          time: this.time,
          e_iroda_ugyszam: this.e_iroda_ugyszam,
          telepito_ceg: this.telepito_ceg,
          szamlazasi_nev: this.szamlazasi_nev,
          szamlazasi_nev2: this.szamlazasi_nev2,
          szamlazasi_cim: this.szamlazasi_cim,
          szamlazasi_cim2: this.szamlazasi_cim2,
          szamla_kuldes_tipusa: this.szamla_kuldes_tipusa,
          adoszam: this.adoszam,
          uploader_id: this.uploader_id,
          uploader_name: this.uploader_name,
          ugyfel_email: this.ugyfel_email,
          ugyfel_email2: this.ugyfel_email2,
          ugyfel_telefon: this.ugyfel_telefon,
          objektum_cim: this.objektum_cim,
          objektum_cim2: this.objektum_cim2,
          objektum_azonosito: this.objektum_azonosito,
          fizetes: this.fizetes,
          megjegyzes: this.megjegyzes,
          fizetendo_osszegM: this.fizetendo_osszegM,
          fizetendo_osszegA: this.fizetendo_osszegA,
          munkak: this.munkak,
          anyagok: this.anyagok,
          authToken: this.authToken,
          firestore_id: this.firestore_id,
          completeDate: this.completeDate,
          dirty: this.dirty,
          archive: this.archive,
        },
      });
    },
    downloadPDF() {
      this.$emit("viewPDF", "download");
    },
    viewPDF() {
      this.$emit("viewPDF", "view");
    },
  },
};
</script>

<style>
.resize {
  background-color: orange;
  width: 300px;
  height: 300px;
  margin: 0 auto;
  resize: both;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.break_long_text {
  /* word-break: break-all; */
  /* word-break: break-word; */
  /* word-break: keep-all; */
  /* word-wrap: none; */
  /* word-break: inherit; */
  /* word-break: initial; */
  word-break: normal;
  /* hyphens: auto; */
  hyphens: inherit;
}
</style>
