<template>
    <div class="signup container" height="100%">
        <v-snackbar v-model="reg_successful" :timeout="3000">
            Regisztráció sikeres!
                <template v-slot:action="{ attrs }">
                    <v-btn color="blue" text v-bind="attrs" @click="reg_successful = false">
                        Ok
                    </v-btn>
                </template>
        </v-snackbar>
        <v-snackbar v-model="reg_failed" :timeout="3000">
            Regisztráció sikertelen :(
                <template v-slot:action="{ attrs }">
                    <v-btn color="blue" text v-bind="attrs" @click="reg_failed = false">
                        Ok
                    </v-btn>
                </template>
        </v-snackbar>
            <v-card>
                <v-form ref="form" v-model="valid" @submit.prevent="signup">
                <h2 align="center" class="blue--text mt-n2 pb-8 pt-3">Regisztráció</h2>


                <!-- NÉV -->
                <v-text-field v-model="name" label="Név" required solo class="px-8 py-5" :rules="usernameRules"></v-text-field>

                <!-- E-MAIL -->
                <v-text-field v-model="email" label="E-mail" required :rules="emailRules" solo class="px-8 py-5"></v-text-field>

                <!-- JELSZÓ -->
                <v-text-field v-model="password" label="Jelszó" required @click:append="showPassword = !showPassword" :rules="passwordRules" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" solo class="px-8 py-5"></v-text-field>
                
                <!-- TELEFONSZÁM -->
                <v-text-field v-model="phone_number" label="Telefonszám(+36xxxxxxxxx)" required :rules="phoneRules" solo class="px-8 py-5"></v-text-field>

                <!-- JOGOSULTSÁG -->
                <v-select :items="$store.state.jogosultsag" label="Jogosultság" v-model="permission" required :rules="permissionRules" solo @change="(item) => permission = item" class="px-8 py-5">
                    <template v-slot:selection="{ item }">
                        <span class="d-flex justify-center" style="width: 100%;">
                        {{ item }}
                        </span>
                    </template>
                </v-select>

                <!-- Signup button -->
                <div class="pb-4" align="center">
                    <v-btn v-ripple @click.prevent="signup" v-if="isLoading == false" :disabled="!valid">Regisztrálás</v-btn>
                    <vue-ellipse-progress 
                        v-if="isLoading"                   
                        :progress="progress"
                        :size="30"
                        :thickness="2"
                        :loading="true"
                        :noData="false"
                        > 
                    </vue-ellipse-progress>
                </div>
            </v-form>
            </v-card>
    </div>
</template>

<script>
import db from '@/firebase/init'
import firebase from 'firebase'
import functions from 'firebase/functions'
import signup_firebaseApp from '@/firebase/signup_init'

export default {
    name: 'Signup',
    data(){
        return {
            name: "",
            email: "",
            password: "",
            phone_number: null,
            slug: null,
            valid: true,
            showPassword: false,
            permission: null,
            admin: false,
            reg_successful: false,
            reg_failed: false,
        usernameRules: [
        v => !!v || 'A mező kitöltése kötelező',
      ],
      emailRules: [
        v => !!v || 'A mező kitöltése kötelező',
        v => /.+@.+\..+/.test(v) || 'Helytelen formátum',
      ],
      phoneRules: [
        v => !!v || 'A mező kitöltése kötelező',
        v => /\+36/.test(v) || 'Helytelen formátum',
      ],
      passwordRules: [
        v => !!v || 'A mező kitöltése kötelező',
        v => (v && v.length >= 8) || 'A jelszónak legalább 8 karakternek kell lennie',
      ],
        permissionRules: [
        v => !!v || 'A mező kitöltése kötelező',
      ],
      isLoading: false,
      progress: 0,
        }
    },
    methods: {
        signup(){
            this.isLoading = true
            if(this.name && this.email && this.password && this.permission && this.phone_number){
                    if(this.permission == "Admin"){
                        this.admin = true
                    }
                    signup_firebaseApp.auth().createUserWithEmailAndPassword(this.email, this.password)
                        .then(cred => {
                            db.collection('users').doc(this.email).set({
                                name: this.name,
                                user_id: cred.user.uid,
                                admin: this.permission == "Admin",
                                phone_number: this.phone_number,
                                
                            }).then(
                                signup_firebaseApp.auth().signOut(),
                                // this.name = "",
                                // this.email = "",
                                // this.password = "",
                                this.$refs.form.reset(),
                                this.slug = null,
                                this.valid = true,
                                this.showPassword = false,
                                this.permission = null,
                                this.admin = false,
                                this.reg_successful = true,
                                this.isLoading = false,
                                )
                        })
                        .catch(err => {
                            this.feedback = err.message
                            this.isLoading = false
                            this.reg_failed = true
                      }),
                      this.admin = false;
            } else {
                this.feedback = "Minden mező kitöltése kötelező"
            }
        }

    },
    mounted(){
        this.valid= false
    }
}
</script>

<style>
.signup{
    max-width: 400px;
    margin-top: 60px;
    margin-bottom: 60px;
    
}
.signup h2{
    font-size: 2.4em;
}
.signup .field{
    margin-bottom: 16px;
}
.card-panel .h2{
    align-content: center;
}
.container{
    outline: 0;
}


</style>