<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-menu
          v-model="showMenu"
          absolute
          offset-y
          style="max-width: 600px, align-items: center, justify-content: center, position: center"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- Money Approval indicator -->
            <v-card
              :color="color2"
              height="15"
              class="my-2 mx-4 mb-n12 rounded-xl top_indicator"
              elevation="10"
              rounded
            ></v-card>
            <v-card
              class="my-10 rounded-xl"
              elevation="3"
              :color="
                email_failed == 'true' ? 'red lighten-2' : 'grey lighten-2'
              "
              :ripple="false"
              v-bind="attrs"
              v-on="on"
            >
              <v-row align="center" justify="space-around">
                <!-- Desktop -->
                <v-col
                  class="d-flex align-center justify-center"
                  cols="1"
                  v-if="!isMobile"
                >
                  <h4>
                    {{ objektum_azonosito }}
                  </h4>
                </v-col>
                <v-col
                  class="d-flex align-center justify-center"
                  sm="3"
                  md="5"
                  xl="5"
                  v-if="!isMobile"
                >
                  <h3 class="my-2">
                    {{ ugyfel_nev | truncate($store.state.charlen, "...") }}
                  </h3>
                </v-col>
                <v-col
                  class="d-flex align-center justify-center my-3"
                  cols="3"
                  v-if="!isMobile"
                >
                  <h4>
                    {{ uploader_name }}
                  </h4>
                </v-col>
                <v-col
                  class="d-flex align-center justify-center my-3"
                  v-if="!isMobile"
                >
                  <h4>
                    {{ time }}
                  </h4>
                </v-col>
                <v-col
                  class="align-center justify-space-around"
                  v-if="!isMobile"
                >
                  <ItemCardButtom
                  
                    :id="id"
                    :url="url"
                    :imageid="imageid"
                    :uploader_email="uploader_email"
                    :e_iroda_ugyszam="e_iroda_ugyszam"
                    :ugyfel_nev="ugyfel_nev"
                    :ugyfel_telefon="ugyfel_telefon"
                    :time="time"
                    :uploader_id="uploader_id"
                    :uploader_name="uploader_name"
                    :ugyfel_email="ugyfel_email"
                    :objektum_cim="objektum_cim"
                    :objektum_azonosito="objektum_azonosito"
                    :telepito_ceg="telepito_ceg"
                    :hivatkozas="hivatkozas"
                    :munka_tipusa="munka_tipusa"
                    :szamlazasi_nev="szamlazasi_nev"
                    :szamlazasi_cim="szamlazasi_cim"
                    :szamla_kuldes_tipusa="szamla_kuldes_tipusa"
                    :adoszam="adoszam"
                    :fizetes="fizetes"
                    :fizetendo_osszegM="fizetendo_osszegM"
                    :fizetendo_osszegA="fizetendo_osszegA"
                    :megjegyzes="megjegyzes"
                    @deleteItem="deleteItem"
                    @deleteItem2="deleteItem2"
                    :munkak="munkak"
                    :anyagok="anyagok"
                    :authToken="authToken"
                    :firestore_id="firestore_id"
                    :archive="archive"
                    @viewPDF="viewPDF"
                    :dirty="dirty"
                    :completeDate="completeDate"
                  />
                </v-col>
                <!-- Mobile -->
                <v-col
                  class="d-flex align-center justify-center"
                  cols="2"
                  v-if="isMobile"
                >
                  <h5>
                    {{ objektum_azonosito }}
                  </h5>
                </v-col>
                <v-col
                  class="d-flex align-center justify-center ml-n3"
                  sm="2"
                  md="3"
                  xl="3"
                  v-if="isMobile"
                >
                  <h5 class="my-2 pl-3" v-if="isMobile">
                    {{ ugyfel_nev | truncate(13, "...") }}
                  </h5>
                </v-col>
                <v-col
                  class="d-flex align-center justify-center my-3"
                  cols="2"
                  v-if="isMobile"
                >
                  <h5>
                    {{ uploader_name }}
                  </h5>
                </v-col>

                <v-col
                  class="d-flex align-center justify-center my-3"
                  cols="3"
                  v-if="isMobile"
                >
                  <h5>
                    {{ time }}
                  </h5>
                </v-col>
              </v-row>

              <!-- Approval indicator -->
              <v-card
                :color="color"
                height="6"
                class="my-1 mx-2 mb-n8"
              ></v-card>
            </v-card>
          </template>
          <v-list dense v-if="isMobile">
            <v-list-item-group
              v-model="selectedItem"
              color="primary"
              v-if="archive == 'false'"
            >
              <v-list-item v-for="(item, index) in items" :key="index">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group v-model="selectedItem" color="primary" v-else>
              <v-list-item v-for="(item, index) in archiveItems" :key="index">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ItemCardButtom from "@/components/home/ItemCardButton";
import jsPDF from "jspdf";
import db from "@/firebase/init";
import { isMobile } from "mobile-device-detect";
import "@/fonts/RobotoMono-Bold-normal.js";

function limitStr(string, limit) {
  let str = string;

  if (typeof str === "string" && str.length > limit) {
    str = str.slice(0, limit) + "...";
  }

  return str;
}

export default {
  name: "itemCard",
  props: [
    "url",
    "imageid",
    "ugyfel_nev",
    "id",
    "index",
    "time_original",
    "e_iroda_ugyszam",
    "uploader_id",
    "uploader_name",
    "telepito_ceg",
    "szerelo_telefon",
    "szamlazasi_nev",
    "szamlazasi_cim",
    "szamla_kuldes_tipusa",
    "adoszam",
    "fizetendo_osszegM",
    "fizetendo_osszegA",
    "fizetes",
    "hivatkozas",
    "megjegyzes",
    "munka_tipusa",
    "objektum_azonosito",
    "objektum_cim",
    "ugyfel_email",
    "ugyfel_email2",
    "ugyfel_telefon",
    "uploader_email",
    "uploader_phone_number",
    "munkak",
    "anyagok",
    "authToken",
    "statusz",
    "firestore_id_original",
    "dirty",
    "archive",
    "completeDate",
    "email_failed",
  ],
  components: {
    ItemCardButtom,
  },
  data() {
    return {
      user: null,
      uploadTime: null,
      rimiLogo: null,
      munkadij: null,
      anyagdij: null,
      statusN: this.statusz,
      color: "grey",
      color2: "grey",
      multiplier: 1.0,
      ref: null,
      isMobile: isMobile,
      items: [
        { text: "Szerkesztés", icon: "mdi-pencil" },
        { text: "Letöltés", icon: "mdi-download" },
        { text: "Navigáció", icon: "mdi-navigation" },
      ],
      archiveItems: [{ text: "Letöltés", icon: "mdi-download" }],
      showMenu: false,
      selectedItem: null,
      handling_fee: 0,
      workId: null,
      firestore_id: null,
      time: null,
    };
  },
  created() {
    // WRONG DATE WITH EXTRA 0 PATCH
    if (this.firestore_id_original.length > 15) {
      this.firestore_id =
        this.firestore_id_original.substring(0, 3) +
        this.firestore_id_original.substring(4);
    } else {
      this.firestore_id = this.firestore_id_original;
    }
    if (this.time_original.length > 19) {
      this.time =
        this.time_original.split("-")[0] +
        "-" +
        this.time_original.split("-")[1].substr(1) +
        "-" +
        this.time_original.split("-")[2];
    } else {
      this.time = this.time_original;
    }
    // Fix missing 0 at dates starting from 2023
    if (parseInt(this.time_original.split("-")[1]) < 10 && parseInt(this.time_original.split("-")[0]) > 2022) {
      this.time =
        this.time_original.split("-")[0] +
      "-" +
      "0" +
        this.time_original.split("-")[1] +
        "-" +
        this.time_original.split("-")[2];
    }

    if (this.dirty == "true") {
      this.color = "yellow darken-2";
    } else {
      // Check status
      if (this.statusz == "pending") {
        this.color = "grey";
      } else if (this.statusz == "accepted") {
        this.color = "green";
      } else if (this.statusz == "denied") {
        this.color = "red";
      }
    }

    if (this.fizetes == "Készpénz") {
      this.color2 = "blue";
    }

    // Check multiplier
    if (this.fizetes == "Átutalás") {
      this.multiplier = 1.0;
    } else if (this.fizetes == "Készpénz") {
      this.multiplier = 1.0;
    } else {
      this.multiplier = 1.0;
    }
  },
  watch: {
    selectedItem(val) {
      if (this.archive == "false") {
        if (val == 0) {
          this.$router.push({
            name: "EditCard",
            params: {
              id: this.id,
              url: this.url,
              szerelo_telefon: this.szerelo_telefon,
              ugyfel_telefon: this.ugyfel_telefon,
              munkalap_azonosito: this.munkalap_azonosito,
              imageid: this.imageid,
              uploader_email: this.uploader_email,
              ugyfel_nev: this.ugyfel_nev,
              ugyfel_nev2: this.ugyfel_nev2,
              time: this.time,
              e_iroda_ugyszam: this.e_iroda_ugyszam,
              telepito_ceg: this.telepito_ceg,
              szamlazasi_nev: this.szamlazasi_nev,
              szamlazasi_nev2: this.szamlazasi_nev2,
              szamlazasi_cim: this.szamlazasi_cim,
              szamlazasi_cim2: this.szamlazasi_cim2,
              szamla_kuldes_tipusa: this.szamla_kuldes_tipusa,
              adoszam: this.adoszam,
              uploader_id: this.uploader_id,
              uploader_name: this.uploader_name,
              ugyfel_email: this.ugyfel_email,
              ugyfel_email2: this.ugyfel_email2,
              ugyfel_telefon: this.ugyfel_telefon,
              objektum_cim: this.objektum_cim,
              objektum_azonosito: this.objektum_azonosito,
              fizetes: this.fizetes,
              megjegyzes: this.megjegyzes,
              fizetendo_osszegM: this.fizetendo_osszegM,
              fizetendo_osszegA: this.fizetendo_osszegA,
              munkak: this.munkak,
              anyagok: this.anyagok,
              authToken: this.authToken,
              firestore_id: this.firestore_id,
              completeDate: this.completeDate,
              dirty: this.dirty,
            },
          });
        }
        if (val == 1) {
          this.viewPDF("download");
        }
        if (val == 2) {
          const destination = this.objektum_cim; // replace with your destination
          const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(destination)}`;
          window.open(url, '_blank');
        }
      } else if (this.archive == "true") {
        this.viewPDF("download");
      }
    },
  },


  methods: {
    hexToString(hex) {
      var string = "";
      for (var i = 0; i < hex.length; i += 2) {
        string += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
      }
      return string;
    },

    viewPDF(viewType) {
      // Generate Image
      var img = new Image();
      this.munkadij = 0;
      this.anyagdij = 0;

      // Get status
      var get_status = db.collection("munkalapok").doc(this.id);
      get_status.get().then((doc) => {
        var _status = doc.data().approved;
        var _dirty = doc.data().dirty;

        //Get logo
        img.src = this.$store.getters.getImage("rimilogo");
        img.onload = () => {
          //Generate new doc
          var doc = new jsPDF({
            lineHeightProportion: 2,
            filters: ["ASCIIHexEncode"],
          });
          doc.setFont("courier", "normal");
          // add custom font to file
          doc.addImage(img, "PNG", 5, 5, 20, 20);
          doc.setFontSize(13);
          doc.text(this.telepito_ceg, 30, 13);
          doc.setFontSize(10);
          doc.text("1133 Budapest, Gogol u. 26.", 30, 18);
          doc.setFontSize(28);

          //Dátum
          doc.setFontSize(10);
          if (this.completeDate) {
            doc.text(
              "Telj:" + this.completeDate.replace("-", "/").replace("-", "/"),
              165,
              10
            );
          } else {
            doc.text(
              "Telj:" + this.time.replace("-", "/").replace("-", "/"),
              165,
              10
            );
          }

          //Get center helpers
          doc.setFontSize(19);
          var textWidth =
            (doc.getStringUnitWidth("E-Munkalap") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset, 30, "E-Munkalap");

          //Munkalap azonosító és objektum azonosító
          doc.setFont("courier","bold");
          doc.setFontSize(10);
          // var textWidth = doc.getStringUnitWidth(this.firestore_id) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          // var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(165, 15, this.firestore_id);
          doc.setFont("courier","normal");
          doc.setFontSize(14);
          doc.setFont("courier","bold");
          doc.setTextColor(255, 0, 0);
          var textWidth =
            (doc.getStringUnitWidth(this.objektum_azonosito) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset, 38, this.objektum_azonosito);
          doc.setTextColor(0, 0, 0);

          //Ügyfélszám placeholder
          doc.setFontSize(11);
          doc.setFont("courier","bold");
          doc.text(165, 25, "Ügyszám: ");
          doc.text(183, 25, this.e_iroda_ugyszam);

          // Státusz
          doc.setFontSize(10);
          doc.text(165, 20, "Státusz: ");
          doc.setDrawColor(0);
          if (_dirty == "true") {
            doc.setFont("courier","bold");
            doc.text(182, 20, "P");
            doc.setFont("courier","normal");
            doc.setFillColor(250, 200, 0);
          } else {
            if (_status == "pending") {
              doc.setFont("courier","bold");
              doc.text(182, 20, "N");
              doc.setFont("courier","normal");
              doc.setFillColor(151, 151, 151);
            } else if (_status == "accepted") {
              doc.setFont("courier","bold");
              doc.text(182, 20, "E");
              doc.setFont("courier","normal");
              doc.setFillColor(0, 255, 0);
            } else if (_status == "denied") {
              doc.setFont("courier","bold");
              doc.text(182, 20, "V");
              doc.setFont("courier","normal");
              doc.setFillColor(255, 0, 0);
            }
          }

          doc.rect(186, 16, 10, 5, "FD");

          //Megrendelő email
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(
              "Megrendel" + this.hexToString("f4") + " e-mail"
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset + 16,
            50,
            "Megrendel" + this.hexToString("f4") + " e-mail"
          );

          //Megrendelő email placeholder
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(this.ugyfel_email.substring(0, 25)) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 16, 55, this.ugyfel_email.substring(0, 25));
          if (this.ugyfel_email.length > 25) {
            var textWidth =
              (doc.getStringUnitWidth(this.ugyfel_email.substring(25)) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(textOffset + 16, 60, this.ugyfel_email.substring(25));
          }

          //Megrendelő telefon
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(
              "Megrendel" + this.hexToString("f4") + " telefon"
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset + 16,
            70,
            "Megrendel" + this.hexToString("f4") + " telefon"
          );

          //Megrendelő telefon placeholder
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(this.ugyfel_telefon) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 16, 75, this.ugyfel_telefon);

          //Függőleges Vonal
          var lineOffset = (doc.internal.pageSize.getWidth() - 200) / 2;
          doc.line(150, 53, 150, 80);

          //Szerelő neve
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(
              "Szerel" + this.hexToString("f4") + " neve"
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset + 75,
            50,
            "Szerel" + this.hexToString("f4") + " neve"
          );

          //Szerelő neve placeholder
          //Find ő end replace it
          let corrector1 = "";
          for (var i = 0; i < this.uploader_name.length; i++) {
            if (this.uploader_name[i] == "ő") {
              corrector1 = corrector1 + this.hexToString("f4");
            } else if (this.uploader_name[i] == "Ő") {
              corrector1 = corrector1 + this.hexToString("d4");
            } else if (this.uploader_name[i] == "ű") {
              corrector1 = corrector1 + this.hexToString("fb");
            } else if (this.uploader_name[i] == "Ű") {
              corrector1 = corrector1 + this.hexToString("db");
            } else {
              corrector1 = corrector1 + this.uploader_name[i];
            }
          }

          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(corrector1) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 75, 55, corrector1);

          //Szerelő telefonszáma
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(
              "Szerel" + this.hexToString("f4") + " tel. száma"
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset + 75,
            70,
            "Szerel" + this.hexToString("f4") + " tel. száma"
          );

          //Szerelő telefonszáma placeholder
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(this.szerelo_telefon) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 75, 75, this.szerelo_telefon);

          //Megrendelő neve
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(
              "Megrendel" + this.hexToString("f4") + " neve"
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset - 59,
            50,
            "Megrendel" + this.hexToString("f4") + " neve"
          );

          //Megrendelő neve placeholder
          //Find ő end replace it
          let corrector2 = "";
          for (var i = 0; i < this.ugyfel_nev.length; i++) {
            if (this.ugyfel_nev[i] == "ő") {
              corrector2 = corrector2 + this.hexToString("f4");
            } else if (this.ugyfel_nev[i] == "Ő") {
              corrector2 = corrector2 + this.hexToString("d4");
            } else if (this.ugyfel_nev[i] == "ű") {
              corrector2 = corrector2 + this.hexToString("fb");
            } else if (this.ugyfel_nev[i] == "Ű") {
              corrector2 = corrector2 + this.hexToString("db");
            } else {
              corrector2 = corrector2 + this.ugyfel_nev[i];
            }
          }
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(corrector2.substring(0, 40)) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset - 59, 55, corrector2.substring(0, 40));
          if (corrector2.length > 40) {
            var textWidth =
              (doc.getStringUnitWidth(corrector2.substring(40)) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(textOffset - 59, 60, corrector2.substring(40));
          }

          //Megrendelő címe
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(
              "Megrendel" + this.hexToString("f4") + " címe"
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset - 59,
            70,
            "Megrendel" + this.hexToString("f4") + " címe"
          );

          //Megrendelő címe placeholder
          //Find ő end replace it
          let corrector3 = "";
          for (var i = 0; i < this.objektum_cim.length; i++) {
            if (this.objektum_cim[i] == "ő") {
              corrector3 = corrector3 + this.hexToString("f4");
            } else if (this.objektum_cim[i] == "Ő") {
              corrector3 = corrector3 + this.hexToString("d4");
            } else if (this.objektum_cim[i] == "ű") {
              corrector3 = corrector3 + this.hexToString("fb");
            } else if (this.objektum_cim[i] == "Ű") {
              corrector3 = corrector3 + this.hexToString("db");
            } else {
              corrector3 = corrector3 + this.objektum_cim[i];
            }
          }
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(corrector3.substring(0, 40)) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset - 59, 75, corrector3.substring(0, 40));
          if (corrector3.length > 40) {
            var textWidth =
              (doc.getStringUnitWidth(corrector3.substring(40)) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(textOffset - 59, 80, corrector3.substring(40));
          }

          //Vonal
          var lineOffset = (doc.internal.pageSize.getWidth() - 200) / 2;
          doc.line(lineOffset + 5, 82, 200, 82);

          //Elvégzett munkák
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Elvégzett munkák") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset - 75, 90, "Elvégzett munkák");

          //Munkadíjak
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Munkadíjak") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset - 30, 90, "Munkadíjak");

          //Függőleges Vonal
          var lineOffset = (doc.internal.pageSize.getWidth() - 200) / 2;
          doc.line(lineOffset + 97, 82, 102, 200);

          //Elvégzett munka lista
          doc.setFontSize(8);
          var index = 0;
          var local_munkak = this.munkak.split(";");
          for (const munka of local_munkak) {
            var data = munka.split(",");
            // Correct special characters
            data[1] = data[1].replace(/ß/g, ",");
            data[1] = data[1].replace(/ł/g, ";");
            data[2] = data[2].replace(/ß/g, ",");
            data[2] = data[2].replace(/ł/g, ";");
            //Find ő end replace it
            let corrector4 = "";
            for (var i = 0; i < data[2].length; i++) {
              if (data[2][i] == "ő") {
                corrector4 = corrector4 + this.hexToString("f4");
              } else if (data[2][i] == "Ő") {
                corrector4 = corrector4 + this.hexToString("d4");
              } else if (data[2][i] == "ű") {
                corrector4 = corrector4 + this.hexToString("fb");
              } else if (data[2][i] == "Ű") {
                corrector4 = corrector4 + this.hexToString("db");
              } else {
                corrector4 = corrector4 + data[2][i];
              }
            }
            var textWidth =
              (doc.getStringUnitWidth(corrector4.substring(0, 25)) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(
              textOffset - 75,
              100 + index * 10,
              corrector4.substring(0, 25)
            );
            var textWidth =
              (doc.getStringUnitWidth(
                new Intl.NumberFormat("hu-HU", {
                  style: "currency",
                  currency: "HUF",
                  maximumFractionDigits: 0,
                }).format(data[3])
              ) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(
              textOffset - 30,
              100 + index * 10,
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(data[3])
            );
            this.munkadij = this.munkadij + parseInt(data[3]);
            index++;
          }

          //Össz. munkadíj placeholder
          doc.setFontSize(11);
          var textWidth =
            (doc.getStringUnitWidth(
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(this.munkadij.toString())
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset - 30,
            205,
            new Intl.NumberFormat("hu-HU", {
              style: "currency",
              currency: "HUF",
              maximumFractionDigits: 0,
            }).format(this.munkadij.toString()) + " + ÁFA"
          );

          //Felhasznált anyagok
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Felhasznált anyagok") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 20, 90, "Felhasznált anyagok");

          //Felhasznált anyagok darab
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("db") * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 46, 90, "db");

          //Anyagdíj/db
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Ár/db") * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 63, 90, "Ár/db");

          //Összesen anyagdíj
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Anyagdíjak") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 85, 90, "Anyagdíjak");

          //Anyag lista
          doc.setFontSize(8);
          var index = 0;
          if (this.anyagok) {
            var local_anyagok = this.anyagok.split(";");
            for (const anyag of local_anyagok) {
              var data = anyag.split(",");
              // Correct special characters
              data[1] = data[1].replace(/ß/g, ",");
              data[1] = data[1].replace(/ł/g, ";");
              //Find ő end replace it
              let corrector5 = "";
              for (var i = 0; i < data[1].length; i++) {
                if (data[1][i] == "ő") {
                  corrector5 = corrector5 + this.hexToString("f4");
                } else if (data[1][i] == "Ő") {
                  corrector5 = corrector5 + this.hexToString("d4");
                } else if (data[1][i] == "ű") {
                  corrector5 = corrector5 + this.hexToString("fb");
                } else if (data[1][i] == "Ű") {
                  corrector5 = corrector5 + this.hexToString("db");
                } else {
                  corrector5 = corrector5 + data[1][i];
                }
              }
              var textWidth =
                (doc.getStringUnitWidth(corrector5.substring(0, 20)) *
                  doc.internal.getFontSize()) /
                doc.internal.scaleFactor;
              var textOffset =
                (doc.internal.pageSize.getWidth() - textWidth) / 2;
              doc.text(
                textOffset + 20,
                100 + index * 10,
                corrector5.substring(0, 20)
              );
              var textWidth =
                (doc.getStringUnitWidth(data[2]) * doc.internal.getFontSize()) /
                doc.internal.scaleFactor;
              var textOffset =
                (doc.internal.pageSize.getWidth() - textWidth) / 2;
              doc.text(textOffset + 46, 100 + index * 10, data[2]);
              var textWidth =
                (doc.getStringUnitWidth(
                  new Intl.NumberFormat("hu-HU", {
                    style: "currency",
                    currency: "HUF",
                    maximumFractionDigits: 0,
                  }).format(data[3])
                ) *
                  doc.internal.getFontSize()) /
                doc.internal.scaleFactor;
              var textOffset =
                (doc.internal.pageSize.getWidth() - textWidth) / 2;
              doc.text(
                textOffset + 63,
                100 + index * 10,
                new Intl.NumberFormat("hu-HU", {
                  style: "currency",
                  currency: "HUF",
                  maximumFractionDigits: 0,
                }).format(data[3])
              );
              var textWidth =
                (doc.getStringUnitWidth(
                  new Intl.NumberFormat("hu-HU", {
                    style: "currency",
                    currency: "HUF",
                    maximumFractionDigits: 0,
                  }).format(data[2] * data[3])
                ) *
                  doc.internal.getFontSize()) /
                doc.internal.scaleFactor;
              var textOffset =
                (doc.internal.pageSize.getWidth() - textWidth) / 2;
              if (data[2] * data[3] == "-0") {
                doc.text(
                  textOffset + 85,
                  100 + index * 10,
                  new Intl.NumberFormat("hu-HU", {
                    style: "currency",
                    currency: "HUF",
                    maximumFractionDigits: 0,
                  }).format("0")
                );
              } else {
                doc.text(
                  textOffset + 85,
                  100 + index * 10,
                  new Intl.NumberFormat("hu-HU", {
                    style: "currency",
                    currency: "HUF",
                    maximumFractionDigits: 0,
                  }).format(data[2] * data[3])
                );
              }
              this.anyagdij = this.anyagdij + parseInt(data[2] * data[3]);
              index++;
            }
          }

          //Össz. anyagdíj placeholder
          doc.setFontSize(11);
          var textWidth =
            (doc.getStringUnitWidth(
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(this.anyagdij.toString())
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset + 65,
            205,
            new Intl.NumberFormat("hu-HU", {
              style: "currency",
              currency: "HUF",
              maximumFractionDigits: 0,
            }).format(this.anyagdij.toString()) + " + ÁFA"
          );

          //Vonal
          var lineOffset = (doc.internal.pageSize.getWidth() - 200) / 2;
          doc.line(lineOffset + 5, 94, 200, 94);

          // Adminisztrációs költség 2%
          if (this.fizetes == "Átutalás") {
            doc.setFontSize(11);
            doc.setFont("courier","bold");
            var textWidth =
              (doc.getStringUnitWidth("Adminisztrációs költség 2%:") *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(textOffset + 42, 210, "Adminisztrációs költség 2%:");
            var textWidth =
              (doc.getStringUnitWidth(
                new Intl.NumberFormat("hu-HU", {
                  style: "currency",
                  currency: "HUF",
                  maximumFractionDigits: 0,
                }).format(
                  (
                    (this.munkadij + this.anyagdij) *
                    this.multiplier *
                    0.02
                  ).toString()
                )
              ) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(
              180,
              210,
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(
                (
                  (this.munkadij + this.anyagdij) *
                  this.multiplier *
                  0.02
                ).toString()
              )
            );
            doc.setFont("courier","normal");
            this.handling_fee =
              (this.munkadij + this.anyagdij) * this.multiplier * 0.02;
          }

          if (this.fizetes == "Átutalás") {
            //Össz. össz. nettó
            doc.setFontSize(11);
            doc.setFont("courier","bold");
            var textWidth =
              (doc.getStringUnitWidth("Összesen nettó:") *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(textOffset + 28, 215, "Összesen nettó:");

            //Össz. össz. nettó placeholder
            doc.setFontSize(11);
            doc.setFont("courier","bold");
            var textWidth =
              (doc.getStringUnitWidth(
                new Intl.NumberFormat("hu-HU", {
                  style: "currency",
                  currency: "HUF",
                  maximumFractionDigits: 0,
                }).format(
                  (
                    (this.munkadij + this.anyagdij + this.handling_fee) *
                    this.multiplier
                  ).toString()
                )
              ) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(
              180,
              215,
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(
                (
                  (this.munkadij + this.anyagdij + this.handling_fee) *
                  this.multiplier
                ).toString()
              )
            );
            doc.setFont("courier","normal");
          } else {
            //Össz. össz. nettó
            doc.setFontSize(11);
            doc.setFont("courier","bold");
            var textWidth =
              (doc.getStringUnitWidth("Összesen nettó:") *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(textOffset + 28, 215, "Összesen nettó:");

            //Össz. össz. nettó placeholder
            doc.setFontSize(11);
            doc.setFont("courier","bold");
            var textWidth =
              (doc.getStringUnitWidth(
                new Intl.NumberFormat("hu-HU", {
                  style: "currency",
                  currency: "HUF",
                  maximumFractionDigits: 0,
                }).format(
                  ((this.munkadij + this.anyagdij) * this.multiplier).toString()
                )
              ) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(
              180,
              215,
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(
                ((this.munkadij + this.anyagdij) * this.multiplier).toString()
              )
            );
            doc.setFont("courier","normal");
          }

          //Össz. össz. bruttó
          doc.setFontSize(11);
          doc.setFont("courier","bold");
          var textWidth =
            (doc.getStringUnitWidth("Összesen bruttó:") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(textOffset + 29, 220, "Összesen bruttó:");

          if (this.fizetes == "Átutalás") {
            //Össz. össz. bruttó placeholder
            doc.setFontSize(11);
            doc.setFont("courier","bold");
            var textWidth =
              (doc.getStringUnitWidth(
                new Intl.NumberFormat("hu-HU", {
                  style: "currency",
                  currency: "HUF",
                  maximumFractionDigits: 0,
                }).format(
                  (
                    ((this.munkadij + this.anyagdij) * this.multiplier +
                      (this.munkadij + this.anyagdij) *
                        this.multiplier *
                        0.02) *
                    1.27
                  ).toString()
                )
              ) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(
              180,
              220,
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(
                (
                  ((this.munkadij + this.anyagdij) * this.multiplier +
                    (this.munkadij + this.anyagdij) * this.multiplier * 0.02) *
                  1.27
                ).toString()
              )
            );
            doc.setFont("courier","normal");
          } else {
            //Össz. össz. bruttó placeholder
            doc.setFontSize(11);
            doc.setFont("courier","bold");
            var textWidth =
              (doc.getStringUnitWidth(
                new Intl.NumberFormat("hu-HU", {
                  style: "currency",
                  currency: "HUF",
                  maximumFractionDigits: 0,
                }).format(
                  (
                    (this.munkadij + this.anyagdij) *
                    this.multiplier *
                    1.27
                  ).toString()
                )
              ) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(
              180,
              220,
              new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(
                (
                  (this.munkadij + this.anyagdij) *
                  this.multiplier *
                  1.27
                ).toString()
              )
            );
            doc.setFont("courier","normal");
          }

          // Átutalás megjegyzés
          if (this.fizetes != "Készpénz") {
            doc.setFontSize(10);
            doc.text(
              15,
              225,
              "Készpénz fizetés esetén a 2% adminisztrációs költséget elengedjük."
            );
          }

          //Vonal
          var lineOffset = (doc.internal.pageSize.getWidth() - 200) / 2;
          doc.line(lineOffset + 5, 228, 200, 228);

          //Számla adószám
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Adószám:") * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(10, 235, "Adószám:");

          //Számla adószám placeholder
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(this.adoszam) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(28, 235, this.adoszam);

          //Számlaküldési mód
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Számlaküldés módja:") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(60, 235, "Számlaküldés módja:");

          //Számlaküldési mód placeholder
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(this.szamla_kuldes_tipusa) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(101, 235, this.szamla_kuldes_tipusa);

          //Fizetési mód
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Fizetési mód:") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(140, 235, "Fizetési mód:");

          //Fizetési mód placeholder
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(this.fizetes) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          if (this.fizetes == "Fix összegű utalás") {
            doc.text(
              168,
              235,
              "Fix összeg" + this.hexToString("fb") + " utalás"
            );
          } else {
            doc.text(168, 235, this.fizetes);
          }

          //Számlázási név
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Számlázási név:") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(10, 249, "Számlázási név:");

          //Számlázási név placeholder
          //Find ő end replace it
          let corrector6 = "";

          for (var i = 0; i < this.szamlazasi_nev.length; i++) {
            if (this.szamlazasi_nev[i] == "ő") {
              corrector6 = corrector6 + this.hexToString("f4");
            } else if (this.szamlazasi_nev[i] == "Ő") {
              corrector6 = corrector6 + this.hexToString("d4");
            } else if (this.szamlazasi_nev[i] == "ű") {
              corrector6 = corrector6 + this.hexToString("fb");
            } else if (this.szamlazasi_nev[i] == "Ű") {
              corrector6 = corrector6 + this.hexToString("db");
            } else {
              corrector6 = corrector6 + this.szamlazasi_nev[i];
            }
          }
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(corrector6.substring(0, 75)) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(42, 249, corrector6.substring(0, 75));
          // LONGER TEXT WILL BE CUT
          // if(corrector6.length > 75){
          //   var textWidth = doc.getStringUnitWidth(corrector6.substring(75)) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          //   var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          //   doc.text(42, 263, corrector6.substring(75));
          // }

          //Számlázási cím
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth("Számlázási cím:") *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(10, 242, "Számlázási cím:");

          //Számlázási cím placeholder
          //Find ő end replace it

          let corrector7 = "";
          for (var i = 0; i < this.szamlazasi_cim.length; i++) {
            if (this.szamlazasi_cim[i] == "ő") {
              corrector7 = corrector7 + this.hexToString("f4");
            } else if (this.szamlazasi_cim[i] == "Ő") {
              corrector7 = corrector7 + this.hexToString("d4");
            } else if (this.szamlazasi_cim[i] == "ű") {
              corrector7 = corrector7 + this.hexToString("fb");
            } else if (this.szamlazasi_cim[i] == "Ű") {
              corrector7 = corrector7 + this.hexToString("db");
            } else {
              corrector7 = corrector7 + this.szamlazasi_cim[i];
            }
          }
          doc.setFontSize(10);
          var textWidth =
            (doc.getStringUnitWidth(corrector7.substring(0, 75)) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(42, 242, corrector7.substring(0, 75));
          // LONGER TEXT WILL BE CUT
          // if(corrector7.length > 75){
          //   var textWidth = doc.getStringUnitWidth(corrector7.substring(75)) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          //   var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          //   doc.text(42, 250, corrector7.substring(75));
          // }

          //Megjegyzés
          //Find ő end replace it
          let corrector8 = "";
          for (var i = 0; i < this.megjegyzes.length; i++) {
            if (this.megjegyzes[i] == "ő") {
              corrector8 = corrector8 + this.hexToString("f4");
            } else if (this.megjegyzes[i] == "Ő") {
              corrector8 = corrector8 + this.hexToString("d4");
            } else if (this.megjegyzes[i] == "ű") {
              corrector8 = corrector8 + this.hexToString("fb");
            } else if (this.megjegyzes[i] == "Ű") {
              corrector8 = corrector8 + this.hexToString("db");
            } else {
              corrector8 = corrector8 + this.megjegyzes[i];
            }
          }
          doc.setFontSize(10);
          var prefix = "Megjegyzés: ";
var textWidth = (doc.getStringUnitWidth(prefix) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;

var textWidth = (doc.getStringUnitWidth(corrector8) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;

const firstLineText = prefix + corrector8.replace(/\r?\n|\r/gm, "").substring(0, 80-prefix.length+4);
const remainingText = corrector8.replace(/\r?\n|\r/gm, "").substring(80-prefix.length+4);
const splitText = doc.splitTextToSize(remainingText, 179);

// If there is more than one line, adjust the last line
if (splitText.length > 1) {
    splitText[splitText.length - 1] = splitText[splitText.length - 1].substring(0, splitText[splitText.length - 1].length + prefix.length);
}

doc.text(10, 256, firstLineText);
doc.text(10, 260, splitText);



          //Közlemények
          doc.setFontSize(9);
          doc.setFont("courier","bold");
          var textWidth =
            (doc.getStringUnitWidth(
              "A felhasznált anyagok a vételár teljes kiegyenlítéséig a kivitelez" +
                this.hexToString("f4") +
                " tulajdonát képezik"
            ) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
          doc.text(
            textOffset,
            292,
            "A felhasznált anyagok a vételár teljes kiegyenlítéséig a kivitelez" +
              this.hexToString("f4") +
              " tulajdonát képezik"
          );
          doc.setFont("courier","normal");

          if (isMobile) {
            doc.save(this.ugyfel_nev + "_" + this.time.split(" ")[0] + ".pdf");
          } else if (!isMobile) {
            if (viewType == "view") {
              //Print new page
              var string = doc.output("dataurlstring");
              var iframe =
                "<iframe width='100%' height='100%' src='" +
                string +
                "'></iframe>";
              var x = window.open(string);
              x.document.open();
              x.document.write(iframe);
              x.document.close();
            } else if (viewType == "download") {
              doc.save(
                this.ugyfel_nev + "_" + this.time.split(" ")[0] + ".pdf"
              );
            }
          }
        };
      });
    },
    deleteItem(ids) {
      this.$emit("deleteItem", ids);
    },
    deleteItem2(ids) {
      this.$emit("deleteItem2", ids);
    },
  },
};
</script>
<style>
.v-list-item__title {
  justify-content: center;
  align-content: center;
  text-align: left;
  display: grid;
}
.v-list-item__icon {
  align-self: center;
}
.top_indicator {
  border-radius: 90%;
}
</style>
