<template>
    <div>
      <v-card color="blue darken-4" class="mb-8">
        <v-row align="center" justify="center">
          <v-col>
            <v-select :items="munka_hivatkozasok" v-model="hivatkozas" class="vmenu mx-4 mb-n3" label="Hivatkozás" solo @change="(item) => _updateJob('updateJob', jobID, 'jobRef', item)" :menu-props="{ auto: true }" :disabled="!$store.state.user.admin && archive == 'true'">
              <template v-slot:selection="{ item }">
                <span class="d-flex justify-center" style="width: 100%;">
                  {{ item }}
                </span>
              </template>
            </v-select>
            <v-select :disabled="!$store.state.user.admin && archive == 'true'" :items="munka_tipusok" v-if="hivatkozas != 'Egyedi'" v-model="munka_tipus" label="Munka típusa" solo @change="(item) => _updateJob('updateJob', jobID, 'jobType', item)" class="categoryStyled mx-4 mb-n8">
              <template v-slot:selection="{ item }">
                <span class="d-flex justify-center" style="width: 100%;">
                  {{ item }}
                </span>
              </template>
            </v-select>
            <v-text-field :disabled="!$store.state.user.admin && archive == 'true'" v-model="munka_tipus" label="Munka típusa" v-if="hivatkozas == 'Egyedi'" v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'" @change="(item) => _updateJob('updateJob', jobID, 'jobType', item)" type="text" dense solo single-line class="mx-4 mb-n7" />
            <v-text-field :disabled="!$store.state.user.admin && archive == 'true'" v-model="munka_ar" v-if="munka_tipus != 'Díjkedvezmény'" v-mask="'###########'" label="Ár" @change="_updateJobPrice" dense solo single-line class="mx-4 mt-5 mb-n7" />
            <v-text-field :disabled="!$store.state.user.admin && archive == 'true'" v-model="discount" v-if="munka_tipus == 'Díjkedvezmény'" type="number" v-mask="'###########'" @change="_discountJob" dense solo single-line class="mx-4 mt-5 mb-n7" />
            <v-row align="center" justify="center" class="mt-2 mb-2">
              <v-btn :disabled="!$store.state.user.admin && archive == 'true'" depressed color="grey lighten-3" @click.prevent="_deleteJob" class="mb-n4">
              Törlés
            </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </div>
</template>

<script>
import db from '@/firebase/init'

export default {
    name: 'UploadJobCard',
    props: ['jobID', "jobType", "jobRef", "jobPrice", "index", "archive"],

  data(){
	return{
        munka_tipusok: [],
        munka_arak: [],
        munka_hivatkozasok: [],
        munka_tipus: this.jobType,
        egyedi_munka: "",
        hivatkozas: this.jobRef,
        munka_ar: 0,
        discount: 0,
    }
  },
  methods: {
      _updateJob(func, jobID, key, data){
          this.$emit(func, {'jobID':jobID, 'key': key, 'data': data})
          if(key == "jobType") {
              this.munka_tipus = data
              if(this.hivatkozas != "Egyedi"){
                this.munka_tipusok.forEach((job, index) => {
                  if(job == data){
                      if(isNaN(parseInt(this.munka_arak[index]))){
                        this.$emit("updateJob", {'jobID': jobID, 'key':"jobPrice", 'data': 0})
                        this.munka_ar = 0
                      }
                      else {
                        this.$emit("updateJob", {'jobID': jobID, 'key':"jobPrice", 'data': parseInt(this.munka_arak[index])})
                        this.munka_ar = this.munka_arak[index]
                      }  
                  }
              }) 
              }
              else if(this.hivatkozas == "Egyedi") {
                this.munka_ar = 0
                this.$emit("updateJob", {'jobID': jobID, 'key':"jobPrice", 'data': parseInt(this.munka_ar)})
              }

          }
          else if(key == "jobRef") {
              this.hivatkozas = data
          }
      },
      _discountJob(){
          this.$emit("updateJob", {'jobID': this.jobID, 'key':"jobPrice", 'data': this.discount*-1})
      },
      _updateJobPrice(){
        //Check if price is null
        if(!this.munka_ar){
            this.munka_ar = 0
              }
        this.$emit("updateJob", {'jobID': this.jobID, 'key':"jobPrice", 'data': parseInt(this.munka_ar)})
      },
      _deleteJob(){
        this.$emit("deleteJob", this.index)
      },
      
  },

  created(){
    if(this.jobType == "Díjkedvezmény"){
      this.discount = this.jobPrice*-1
    }
    else {
      this.munka_ar = this.jobPrice
    }
        //fetch data from the firestore
        db.collection('arcimkek').get().then(snapshot => {
            snapshot.forEach(doc => {
              this.munka_tipusok.push(doc.data().azonosito)
              this.munka_arak.push(doc.data().ar)
                })
            })
        db.collection('hivatkozasok').get().then(snapshot => {
            snapshot.forEach(doc => {
              this.munka_hivatkozasok.push(doc.data().azonosito)
                })
            })

            
  },
}
</script>
<style>

</style>