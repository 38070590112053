import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyAuYwbITj-IVa7tVwxTMA32S01_J42ssoM",
    authDomain: "e-munkalap-f65fe.firebaseapp.com",
    databaseURL: "https://e-munkalap-f65fe-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "e-munkalap-f65fe",
    storageBucket: "e-munkalap-f65fe.appspot.com",
    messagingSenderId: "851931148082",
    appId: "1:851931148082:web:d4adb813eb191b0856712b"
  };
    
    const signup_firebaseApp = firebase.initializeApp(firebaseConfig, "signup");
  
    export default signup_firebaseApp