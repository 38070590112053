<template>
  <v-app>
    <nav>
      <!-- Appbar -->
      <v-app-bar app light height="75" class="app_bar">
        <div id="rimi_logo">
          <v-toolbar-title>
            <router-link
              :to="{
                name: 'HomePage',
                params: {
                  admin: $store.state.isAdmin,
                  id: $store.state.user_id,
                },
              }"
            >
              <v-layout>
                <v-flex fill-height class="d-flex align-center">
                  <img src="./assets/rimi2.png" class="mr-4" />
                  <h5 class="center">
                    E-Munkalap
                  </h5>
                </v-flex>
              </v-layout>
            </router-link>
          </v-toolbar-title>
        </div>
        <v-layout row justify-end class="mr-n12">
          <v-flex shrink>
            <v-row align="center" justify="center" class="mr-n6">
              <!-- If on desktop  -->
              <v-col cols="3" class="d-flex align-center justify-center">
                <router-link
                  :to="{ name: 'Upload' }"
                  v-if="this.$route.name == 'HomePage'"
                >
                  <v-btn
                    color="blue"
                    fab
                    dark
                    small
                    class="d-flex align-center"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </router-link>
              </v-col>
              <!-- Regisztrálás kártya -->
              <v-col cols="3" class="d-flex justify-space-around mx-n2">
                <!-- Admin wrench -->
                <v-menu
                  offset-y
                  v-if="$store.state.isAdmin && $route.name != 'Login'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="blue"
                      fab
                      dark
                      small
                      class="d-flex align-center"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-wrench</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                    v-if="$route.name != item.route"
                      v-for="(item, index) in admin_items"
                      :key="index"
                      link
                    >
                      <v-list-item-title
                        class="d-flex align-center"
                        @click.prevent="goTo(item.title)"
                        >{{ item.title }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!-- User wrench -->
                <v-menu
                  offset-y
                  v-if="!$store.state.isAdmin && $route.name != 'Login'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="blue"
                      fab
                      dark
                      small
                      class="d-flex align-center"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-wrench</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                    v-if="$route.name != item.route"
                      v-for="(item, index) in items" 
                      :key="index"
                      link
                    >
                      <v-list-item-title 
                        class="d-flex align-center"
                        @click.prevent="goTo(item.title)"
                        >{{ item.title }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col
                v-if="$store.state.user"
                cols="3"
                class="d-flex justify-space-around mr-4"
              >
                <v-menu
                  v-if="$route.name != 'Login'"
                  bottom
                  min-width="100px"
                  rounded
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-btn icon x-large v-on="on">
                      <v-avatar color="blue" size="40">
                        <v-icon color="white">
                          mdi-account-circle
                        </v-icon>
                      </v-avatar>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list-item-content class="justify-center">
                      <div class="mx-auto text-center">
                        <h3>{{ $store.state.username }}</h3>
                        <p class="text-caption mt-1">
                          {{ $store.state.user.email }}
                        </p>
                        <v-divider class="my-3"></v-divider>
                        <v-btn depressed rounded text @click.prevent="logout">
                          Kijelentkezés
                        </v-btn>
                      </div>
                    </v-list-item-content>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col class="mr-5"> </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </v-app-bar>
      <v-main>
        <router-view></router-view>
      </v-main>
    </nav>
  </v-app>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import rimiLogo from "@/assets/rimi2.png";
import firebase from "firebase";

export default {
  name: "Navbar",
  data() {
    return {
      drawer: false,
      filterStatus: [],
      checked: false,
      checkbox: false,
      activeFilters: [],
      searchField: null,
      isMobile: isMobile,
      admin_items: [
        { title: "Felhasználó regisztrálás", route:"Signup" },
        { title: "Aktív munkalapok", route:"HomePage" },
        { title: "Archív munkalapok", route:"ArchivePage" },
      ],
      items: [{ title: "Aktív munkalapok", route:"HomePage" },{ title: "Archív munkalapok", route:"ArchivePage" }],
    };
  },
  methods: {
    async logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push({ name: "Login" });
          this.$store.commit("setUser", null);
          this.$store.commit("setAdminState", false);
          this.$store.commit("setAllItems", []);
        })
        .catch((error) => {
          this.$router.push({ name: "Login" });
          this.$store.commit("setUser", null);
          this.$store.commit("setAdminState", false);
          this.$store.commit("setAllItems", []);
        });
    },

    runSearchFilter() {
      if (this.searchField.length) {
        // this.$store.commit("setSearchFilter", this.searchField.toLowerCase().split(" "))
        this.$store.commit("setSearchFilter", this.searchField.toLowerCase());
      } else {
        this.$store.commit("setSearchFilter", "");
      }
    },
    goTo(ref) {
      if (ref == "Felhasználó regisztrálás") {
        this.$router.push("Signup");
      } else if (ref == "Archív munkalapok") {
        this.$router.push({
          name: "ArchivePage",
          params: {
            admin: this.$store.state.isAdmin,
            id: this.$store.state.user_id,
          },
        });
      } else if (ref == "Aktív munkalapok") {
        this.$router.push({
          name: "HomePage",
          params: {
            admin: this.$store.state.isAdmin,
            id: this.$store.state.user_id,
          },
        });
      }
    },
  },
  mounted() {
    this.$store.commit("cacheImage", { id: "rimilogo", data: rimiLogo });
  },

  watch: {
    filterStatus: {
      handler: "runFilter",
      // immediate: true
    },

    searchField: {
      handler: "runSearchFilter",
    },
  },
};
</script>

<style>
h5 {
  text-align: center;
}
.app_bar img {
  width: 50px;
  height: 50px;
  /* width: 22%; */
  /* height: 22%; */
}
/* Correct misplacing of checkbox icon */
/* .v-input--selection-controls__input .v-icon{
     padding-bottom: 32px;
      } */
a {
  text-decoration: none;
}
.emailContainer {
  height: 50px;
  align-content: center;
}
.v-list-item__title {
  height: 50px;
}

/* Special rotation block  */

/* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */

/* @media screen and (min-aspect-ratio: 13/9) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
  .bw-dashboard {
    height: inherit;
  }
}
@media screen and (min-width: 1292px) {
  html {
    transform: none;
    transform-origin: none;
    width: 100%;
    overflow-x: none;
    position: relative;
  }
  .bw-dashboard {
    height: 100vh;
  }
} */
</style>
