<template>
  <div class="homePage">
    <!-- Overlay -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-row class="d-flex" align="center" justify="center">
      <v-col class="d-flex align-center justify-center">
        <!-- Searchbar Desktop -->
        <v-row class="d-flex" align="center" justify="center" v-if="!isMobile">
          <v-col class="d-flex align-center justify-center" cols="4">
            <v-text-field
              v-model="searchField2"
              hide-details
              filled
              rounded
              dense
              single-line
              append-icon="mdi-magnify"
              class="mt-4 mb-6"
            ></v-text-field>
          </v-col>
          <v-col class="d-flex align-center justify-center pt-6" cols="2">
            <v-select
              :items="search_types"
              solo
              v-model="search_type"
            ></v-select>
          </v-col>
          <v-col class="d-flex align-center justify-center pt-6" cols="2">
            <v-select
              :items="years"
              solo
              v-model="year"
            ></v-select>
          </v-col>
        </v-row>
        <!-- Searchbar Mobile -->
        <v-row
          class="d-flex mt-2 mb-3"
          align="center"
          justify="center"
          v-if="isMobile"
        >
          <v-col class="d-flex align-center justify-center" cols="6">
            <v-select
              :items="search_types"
              solo
              v-model="search_type"
            ></v-select>
          </v-col>
          <v-col class="d-flex align-center justify-center" cols="4">
            <v-select
              :items="years"
              solo
              v-model="year"
            ></v-select>
          </v-col>
          <v-col
            class="d-flex align-center justify-center mt-n12 mb-n8"
            cols="10"
          >
            <v-text-field
              v-model="searchField2"
              hide-details
              filled
              rounded
              dense
              single-line
              append-icon="mdi-magnify"
              class="mt-4 mb-6"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mt-n8">
      <v-col class="d-flex align-center justify-center" cols="12">
        <!-- Headbar -->
        <v-row align="center" justify="space-around">
          <v-col class="d-flex align-center justify-center">
            <!-- DESKTOP HEADER -->
            <v-expansion-panels
              focusable
              v-model="panelStatus"
              class="mb-n2"
              v-if="!isMobile"
            >
              <v-expansion-panel>
                <v-expansion-panel-header
                  ripple
                  expand-icon=""
                  @click.prevent="blockHeader"
                  color="grey lighten-1"
                  class="rounded"
                >
                  <v-row
                    class="d-flex align-center justify-center my-n6"
                    align="center"
                    justify="space-around"
                  >
                    <v-col
                      class="d-flex align-center justify-center my-3 pr-12"
                      cols="1"
                      @click.prevent="changeSort('customer_number')"
                    >
                      <h3>Ügyfélkód</h3>
                    </v-col>
                    <v-col
                      class="d-flex align-center justify-center my-3"
                      sm="3"
                      md="4"
                      xl="4"
                      @click.prevent="changeSort('customer_name')"
                    >
                      <h3>Ügyfél neve</h3>
                    </v-col>
                    <v-col
                      class="d-flex align-center justify-center my-3"
                      cols="3"
                      @click.prevent="changeSort('uploader_name')"
                    >
                      <h3>Telepítő neve</h3>
                    </v-col>
                    <v-col
                      class="d-flex align-center justify-center my-3"
                      cols="1"
                      @click.prevent="changeSort('time')"
                    >
                      <h3>Idő</h3>
                    </v-col>
                    <v-col
                      class="d-flex align-center justify-center my-3"
                      sm="1"
                      md="1"
                      xl="2"
                    >
                      <h3 class="">Funkciók</h3>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content />
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- MOBILE HEADER -->
            <v-expansion-panels
              focusable
              v-model="panelStatus"
              class="mb-n2"
              v-if="isMobile"
            >
              <v-expansion-panel>
                <v-expansion-panel-header
                  ripple
                  expand-icon=""
                  @click.prevent="blockHeader"
                  color="grey lighten-1"
                  class="rounded"
                >
                  <v-row
                    class="d-flex align-center my-n6"
                    align="center"
                    justify="space-around"
                  >
                    <v-col
                      class="d-flex align-center justify-center my-3 ml-2"
                      cols="2"
                      @click.prevent="changeSort('customer_number')"
                    >
                      <h4>Kód</h4>
                    </v-col>
                    <v-col
                      class="d-flex align-center justify-center my-3"
                      sm="2"
                      md="2"
                      xl="2"
                      @click.prevent="changeSort('customer_name')"
                    >
                      <h4>Ügyfél neve</h4>
                    </v-col>
                    <v-col
                      class="d-flex align-center justify-center my-3"
                      cols="4"
                      @click.prevent="changeSort('uploader_name')"
                    >
                      <h4>Telepítő neve</h4>
                    </v-col>
                    <v-col
                      class="d-flex align-center justify-center my-3"
                      cols="2"
                      @click.prevent="changeSort('time')"
                    >
                      <h4>Idő</h4>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content />
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="filteredItems" align="center" justify="center">
      <v-virtual-scroll
        :items="filteredItems"
        :item-height="itemHeight"
        height="800"
        bench="10"
        v-if="isMobile"
      >
        <template v-slot:default="{ item }">
          <div class="card-container">
            <v-list-item>
              <v-col :key="item.id" cols="12" class="my-n4">
                <itemCard
                  @deleteItem="deleteItem"
                  @deleteItem2="deleteItem2"
                  :key="item.id"
                  :index="item.id"
                  :e_iroda_ugyszam="item.e_iroda_ugyszam"
                  :url="item.url[0]"
                  :imageid="item.imageId"
                  :id="item.id"
                  :ugyfel_nev="item.ugyfel_nev"
                  :ugyfel_nev2="item.ugyfel_nev2"
                  :ugyfel_telefon="item.ugyfel_telefon"
                  :telepito_ceg="item.telepito_ceg"
                  :szerelo_telefon="item.szerelo_telefon"
                  :time_original="item.createdTime"
                  :uploader_id="item.uploaderId"
                  :uploader_name="item.uploaderName"
                  :szamlazasi_nev="item.szamlazasi_nev"
                  :szamlazasi_nev2="item.szamlazasi_nev2"
                  :szamlazasi_cim="item.szamlazasi_cim"
                  :szamlazasi_cim2="item.szamlazasi_cim2"
                  :szamla_kuldes_tipusa="item.szamla_kuldes_tipusa"
                  :adoszam="item.adoszam"
                  :fizetendo_osszegM="item.fizetendo_osszegM"
                  :fizetendo_osszegA="item.fizetendo_osszegA"
                  :fizetes="item.fizetes"
                  :hivatkozas="item.hivatkozas"
                  :megjegyzes="item.megjegyzes"
                  :munka_tipusa="item.munka_tipus"
                  :objektum_azonosito="item.objektum_azonosito"
                  :objektum_cim="item.objektum_cim"
                  :objektum_cim2="item.objektum_cim2"
                  :ugyfel_email="item.ugyfel_email"
                  :ugyfel_email2="item.ugyfel_email2"
                  :uploader_email="item.uploaderEmail"
                  :uploader_phone_number="item.phone_number"
                  :munkak="item.munkak"
                  :anyagok="item.anyagok"
                  :authToken="item.authToken"
                  :statusz="item.approved"
                  :firestore_id_original="item.firestore_id"
                  :dirty="item.dirty"
                  :archive="item.archive"
                  :completeDate="item.completeDate"
                  :email_failed="item.email_failed"
                />
              </v-col>
            </v-list-item>
          </div>
        </template>
      </v-virtual-scroll>
      <v-virtual-scroll
        :items="filteredItems"
        :item-height="itemHeight"
        @resize="onResize"
        height="800"
        bench="10"
        v-else
      >
        <template v-slot:default="{ item }">
            <v-list-item>
              <v-col :key="item.id" cols="12">
                <itemCard @deleteItem="deleteItem" @deleteItem2="deleteItem2"
                :key="item.id" :renderKey="renderKey"" :index="item.id"
                :e_iroda_ugyszam="item.e_iroda_ugyszam" :url="item.url[0]"
                :imageid="item.imageId" :id="item.id"
                :ugyfel_nev="item.ugyfel_nev" :ugyfel_nev2="item.ugyfel_nev2"
                :ugyfel_telefon="item.ugyfel_telefon"
                :telepito_ceg="item.telepito_ceg"
                :szerelo_telefon="item.szerelo_telefon"
                :time_original="item.createdTime" :uploader_id="item.uploaderId"
                :uploader_name="item.uploaderName"
                :szamlazasi_nev="item.szamlazasi_nev"
                :szamlazasi_nev2="item.szamlazasi_nev2"
                :szamlazasi_cim="item.szamlazasi_cim"
                :szamlazasi_cim2="item.szamlazasi_cim2"
                :szamla_kuldes_tipusa="item.szamla_kuldes_tipusa"
                :adoszam="item.adoszam"
                :fizetendo_osszegM="item.fizetendo_osszegM"
                :fizetendo_osszegA="item.fizetendo_osszegA"
                :fizetes="item.fizetes" :hivatkozas="item.hivatkozas"
                :megjegyzes="item.megjegyzes" :munka_tipusa="item.munka_tipus"
                :objektum_azonosito="item.objektum_azonosito"
                :objektum_cim="item.objektum_cim"
                :objektum_cim2="item.objektum_cim2"
                :ugyfel_email="item.ugyfel_email"
                :ugyfel_email2="item.ugyfel_email2"
                :uploader_email="item.uploaderEmail"
                :uploader_phone_number="item.phone_number" :munkak="item.munkak"
                :anyagok="item.anyagok" :authToken="item.authToken"
                :statusz="item.approved"
                :firestore_id_original="item.firestore_id" :dirty="item.dirty"
                :archive="item.archive" :completeDate="item.completeDate"
                :email_failed="item.email_failed" />
              </v-col>
            </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-row>
    <v-layout v-else>
      <v-flex>
        <v-col class="d-flex justify-center">
          <h4>Nincs feltöltött munkalap</h4>
        </v-col>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import db from "@/firebase/init";
import itemCard from "@/components/home/ItemCard";
import { isMobile } from "mobile-device-detect";
import firebase from "firebase";

export default {
  name: "HomePage",
  components: {
    itemCard,
  },

  data() {
    let n = 0;
    return {
      checkbox: false,
      rimiLogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/e-munkalap-f65fe.appspot.com/o/rimi2.png?alt=media&token=5b29ef84-e227-4567-aee6-5860dbcf3fdb",
      dbfetch_finished: false,
      all_items: [],
      allItemsOneshot: true,
      ref: null,
      renderKey: 0,
      oneShot: false,
      searchField: null,
      searchField2: null,
      timer: null,
      years: [],
      year: new Date().getFullYear().toString(),
      panelStatus: 1,
      sorted: "decTime",
      search_type: "Ügyfél neve",
      search_types: ["Ügyfél neve", "Telepítő neve", "Dátum", "Ügyfélkód"],
      isMobile: isMobile,
      overlay: false,
      firstLoad: true,
      height: this.$store.state.functionButtonsHeight,
      mobileHeight: this.$store.state.functionButtonsHeightMobile,
      windowWidth: window.innerWidth,
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  async created() {
    for (let _year =  new Date().getFullYear(); _year >= 2021; _year--) {
    this.years.push(_year.toString());
  }
    if (window.innerWidth > 1200) {
      this.$store.state.charlen = window.innerWidth / 30;
    } else if (window.innerWidth > 1000) {
      this.$store.state.charlen = window.innerWidth / 45;
    } else {
      this.$store.state.charlen = window.innerWidth / 60;
    }
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.overlay = true;
    const user = await this.getUserDataFromFirebase();
    await this.setupDataCollection(user);
    this.overlay = false;
  },

  methods: {

    async findRecord() {
      const getters = this.$store.getters;
      let user = getters.getUser;
      return user && user.user_id;
    },

    async getUserData() {
      return this.$store.getters.getUser;
    },

    async getUserDataFromFirebase() {
      const _user = firebase.auth().currentUser;
      if (!_user) {
        this.$router.push({ name: "Login" });
        return null;
      }

      const doc = await db.collection("users").doc(_user.email).get();
      this.$store.commit("setAdminState", doc.data().admin);
      this.$store.commit("setUser", doc.data());
      this.$store.commit("setWorkerPhone", doc.data().phone_number);
      return doc.data();
    },

    async setupDataCollection(user) {
      if (!user) return;

      this.setupFirestoreRef(user);
      await this.fetchAllItems();
      this.setupSnapshotListener();
      this.firstLoad = false;
      this.$store.commit("setAllItems", this.all_items);
    },

    setupFirestoreRef(user) {
      if (!user.admin) {
        this.ref = db
          .collection("munkalapok")
          .where("archive", "==", "false")
          .where("uploaderId", "==", user.user_id)
          .where("createdTime", ">=", this.year)
          .where("createdTime", "<=", this.year+ "\uf8ff");
      } else {
        this.ref = db.collection("munkalapok").where("archive", "==", "false").where("createdTime", ">=", this.year).where("createdTime", "<=", this.year+ "\uf8ff");
      }
    },

    async fetchAllItems() {
      const querySnapshot = await this.ref.get();
      this.$store.state.all_items = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
    },

    setupSnapshotListener() {
      this.ref.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (!this.firstLoad) {
            if (change.type == "removed") {
              this.removeItem(change);
            } else if (change.type == "added") {
              this.addItem(change);
            } else if (change.type == "modified") {
              this.modifyItem(change);
            }
          }
        });
      });
    },

    removeItem(change) {
      this.$store.state.all_items = this.$store.state.all_items.filter(
        (item) => item.id != change.doc.id
      );
    },

    addItem(change) {
      const newItem = { id: change.doc.id, ...change.doc.data() };
      if (!this.itemExists(change.doc.id) && this.canAddItem(newItem)) {
        this.$store.state.all_items.push(newItem);
      }
    },

    itemExists(id) {
      return this.$store.state.all_items.some(
        (item) => item.id.toString() === id.toString()
      );
    },

    canAddItem(newItem) {
      const { user, isAdmin } = this.$store.state;
      return (
        isAdmin ||
        newItem.uploaderId === user.user_id ||
        newItem.uploaderId === user.uid
      );
    },

    modifyItem(change) {
      const itemIndex = this.$store.state.all_items.findIndex(
        (item) => item.id.toString() === change.doc.id.toString()
      );
      if (itemIndex !== -1) {
        this.$store.state.all_items[itemIndex] = change.doc.data();
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async afterMounted(val) {},
    blockHeader() {
      this.panelStatus = 0;
    },
    deleteItem(ids) {
      db.collection("munkalapok")
        .doc(ids.id)
        .update({
          archive: "true",
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteItem2(ids) {
      db.collection("munkalapok")
        .doc(ids.id)
        .delete()
        .catch((err) => {
          console.log(err);
        });
    },

    correctDateFormat(dateStr) {
      const [year, month, day] = dateStr.split("-");
      const correctedMonth = month.length == 1 ? "0" + month : month;
      const correctDay = day.length == 1 ? "0" + day : day;
      return `${year}-${correctedMonth}-${correctDay}`;
    },

    changeSort(type) {
      // Check if same or different filter is clocked
      if (type == "customer_name" && this.sorted == "decCustomerName") {
        this.sorted = "incCustomerName";
      } else if (type == "customer_name" && this.sorted == "incCustomerName") {
        this.sorted = "decCustomerName";
      } else if (type == "uploader_name" && this.sorted == "decUploaderName") {
        this.sorted = "incUploaderName";
      } else if (type == "uploader_name" && this.sorted == "incUploaderName") {
        this.sorted = "decUploaderName";
      } else if (type == "time" && this.sorted == "decTime") {
        this.sorted = "incTime";
      } else if (type == "time" && this.sorted == "incTime") {
        this.sorted = "decTime";
      } else if (
        type == "customer_number" &&
        this.sorted == "decCustomerNumber"
      ) {
        this.sorted = "incCustomerNumber";
      } else if (
        type == "customer_number" &&
        this.sorted == "incCustomerNumber"
      ) {
        this.sorted = "decCustomerNumber";
      } else if (type == "customer_name") {
        this.sorted = "incCustomerName";
      } else if (type == "uploader_name") {
        this.sorted = "incUploaderName";
      } else if (type == "time") {
        this.sorted = "decTime";
      } else if (type == "customer_number") {
        this.sorted = "incCustomerNumber";
      }
    },

    async updateYear(){
      const user = await this.getUserDataFromFirebase();
      await this.setupDataCollection(user);
      this.setupFirestoreRef(user)
      await this.fetchAllItems()

    }
  },
  watch: {
    windowWidth(val) {
      if (val > 1200) {
        this.$store.state.charlen = val / 30;
      } else if (val > 1000) {
        this.$store.state.charlen = val / 45;
      } else {
        this.$store.state.charlen = val / 60;
      }
    },
    year (val) {
      this.updateYear()
    },
    "$store.state.functionButtonsHeight"(val) {
      this.height = val;
    },
    "$store.state.functionButtonsHeightMobile"(val) {
      this.mobileHeight = val;
    },
    searchField2(val) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.searchField = val;
      }, 300);
    },
    filteredItems(val) {
      this.overlay = false;
    },
  },

  computed: {
    itemHeight() {
       // Customize this function according to your needs
       return isMobile ? this.mobileHeight-10 : this.height+50;
     },

    filteredItems() {
      // Get current time
      var date = new Date();
      date.setFullYear(date.getFullYear() - 1);
      date.setHours(0, 0, 0, 0);
      if (this.searchField && this.$store.state.all_items.length > 0) {
        // Filter by searchfilter
        if (this.search_type == "Ügyfél neve") {
          var sortedItems = this.$store.state.all_items.filter((item) => {
            return this.searchField
              .toLowerCase()
              .split(" ")
              .every((v) => item.ugyfel_nev.toLowerCase().includes(v));
          });
        } else if (this.search_type == "Telepítő neve") {
          var sortedItems = this.$store.state.all_items.filter((item) => {
            return this.searchField
              .toLowerCase()
              .split(" ")
              .every((v) => item.uploaderName.toLowerCase().includes(v));
          });
        } else if (this.search_type == "Dátum") {
          var sortedItems = this.$store.state.all_items.filter((item) => {
            return this.searchField
              .toLowerCase()
              .split(" ")
              .every((v) => item.createdTime.toLowerCase().includes(v));
          });
        } else if (this.search_type == "Ügyfélkód") {
          var sortedItems = this.$store.state.all_items.filter((item) => {
            return this.searchField
              .toLowerCase()
              .split(" ")
              .every((v) => item.objektum_azonosito.toLowerCase().includes(v));
          });
        }

        var archiveItems = sortedItems.filter((o) => o.archive == "false");

        // Customer name
        if (this.sorted == "incCustomerName") {
          return archiveItems.sort((a, b) => {
            if (a.ugyfel_nev > b.ugyfel_nev) {
              return 1;
            }
            if (a.ugyfel_nev < b.ugyfel_nev) {
              return -1;
            }
            return 0;
          });
        } else if (this.sorted == "decCustomerName") {
          return archiveItems.sort((a, b) => {
            if (a.ugyfel_nev < b.ugyfel_nev) {
              return 1;
            }
            if (a.ugyfel_nev > b.ugyfel_nev) {
              return -1;
            }
            return 0;
          });
        }

        // Uploader name
        if (this.sorted == "incUploaderName") {
          return archiveItems.sort((a, b) => {
            if (a.uploaderName > b.uploaderName) {
              return 1;
            }
            if (a.uploaderName < b.uploaderName) {
              return -1;
            }
            return 0;
          });
        } else if (this.sorted == "decUploaderName") {
          return archiveItems.sort((a, b) => {
            if (a.uploaderName < b.uploaderName) {
              return 1;
            }
            if (a.uploaderName > b.uploaderName) {
              return -1;
            }
            return 0;
          });
        }

        // Time
        if (this.sorted == "incTime") {
          return archiveItems.sort((a, b) => {
            if (a.createdTime.length > 19) {
              a.createdTime =
                a.createdTime.split("-")[0] +
                "-" +
                a.createdTime.split("-")[1].substr(1) +
                "-" +
                a.createdTime.split("-")[2];
            }
            if (b.createdTime.length > 19) {
              b.createdTime =
                b.createdTime.split("-")[0] +
                "-" +
                b.createdTime.split("-")[1].substr(1) +
                "-" +
                b.createdTime.split("-")[2];
            }
            let dateA = new Date(this.correctDateFormat(a.createdTime));
            let dateB = new Date(this.correctDateFormat(b.createdTime));
            if (dateA > dateB) {
              return 1;
            }
            if (dateA < dateB) {
              return -1;
            }

            return 0;
          });
        } else if (this.sorted == "decTime") {
          return archiveItems.sort((a, b) => {
            if (a.createdTime.length > 19) {
              a.createdTime =
                a.createdTime.split("-")[0] +
                "-" +
                a.createdTime.split("-")[1].substr(1) +
                "-" +
                a.createdTime.split("-")[2];
            }
            if (b.createdTime.length > 19) {
              b.createdTime =
                b.createdTime.split("-")[0] +
                "-" +
                b.createdTime.split("-")[1].substr(1) +
                "-" +
                b.createdTime.split("-")[2];
            }
            let dateA = new Date(this.correctDateFormat(a.createdTime));
            let dateB = new Date(this.correctDateFormat(b.createdTime));
            if (dateA < dateB) {
              return 1;
            }
            if (dateA > dateB) {
              return -1;
            }
            return 0;
          });
        }

        // Customer nuber
        if (this.sorted == "incCustomerNumber") {
          return archiveItems.sort((a, b) => {
            if (a.objektum_azonosito > b.objektum_azonosito) {
              return 1;
            }
            if (a.objektum_azonosito < b.objektum_azonosito) {
              return -1;
            }
            return 0;
          });
        } else if (this.sorted == "decCustomerNumber") {
          return archiveItems.sort((a, b) => {
            if (a.objektum_azonosito < b.objektum_azonosito) {
              return 1;
            }
            if (a.objektum_azonosito > b.objektum_azonosito) {
              return -1;
            }
            return 0;
          });
        }
      } else if (this.$store.state.all_items.length > 0) {
        var archiveItems = this.$store.state.all_items.filter(
          (o) => o.archive == "false"
        );

        // Customer name
        if (this.sorted == "incCustomerName") {
          return archiveItems.sort((a, b) => {
            if (a.ugyfel_nev > b.ugyfel_nev) {
              return 1;
            }
            if (a.ugyfel_nev < b.ugyfel_nev) {
              return -1;
            }
            return 0;
          });
        } else if (this.sorted == "decCustomerName") {
          return archiveItems.sort((a, b) => {
            if (a.ugyfel_nev < b.ugyfel_nev) {
              return 1;
            }
            if (a.ugyfel_nev > b.ugyfel_nev) {
              return -1;
            }
            return 0;
          });
        }

        // Uploader name
        if (this.sorted == "incUploaderName") {
          return archiveItems.sort((a, b) => {
            if (a.uploaderName > b.uploaderName) {
              return 1;
            }
            if (a.uploaderName < b.uploaderName) {
              return -1;
            }
            return 0;
          });
        } else if (this.sorted == "decUploaderName") {
          return archiveItems.sort((a, b) => {
            if (a.uploaderName < b.uploaderName) {
              return 1;
            }
            if (a.uploaderName > b.uploaderName) {
              return -1;
            }
            return 0;
          });
        }

        // Time
        if (this.sorted == "incTime") {
          return archiveItems.sort((a, b) => {
            if (a.createdTime.length > 19) {
              a.createdTime =
                a.createdTime.split("-")[0] +
                "-" +
                a.createdTime.split("-")[1].substr(1) +
                "-" +
                a.createdTime.split("-")[2];
            }
            if (b.createdTime.length > 19) {
              b.createdTime =
                b.createdTime.split("-")[0] +
                "-" +
                b.createdTime.split("-")[1].substr(1) +
                "-" +
                b.createdTime.split("-")[2];
            }
            let dateA = new Date(this.correctDateFormat(a.createdTime));
            let dateB = new Date(this.correctDateFormat(b.createdTime));
            if (dateA > dateB) {
              return 1;
            }
            if (dateA < dateB) {
              return -1;
            }

            return 0;
          });
        } else if (this.sorted == "decTime") {
          return archiveItems.sort((a, b) => {
            if (a.createdTime.length > 19) {
              a.createdTime =
                a.createdTime.split("-")[0] +
                "-" +
                a.createdTime.split("-")[1].substr(1) +
                "-" +
                a.createdTime.split("-")[2];
            }
            if (b.createdTime.length > 19) {
              b.createdTime =
                b.createdTime.split("-")[0] +
                "-" +
                b.createdTime.split("-")[1].substr(1) +
                "-" +
                b.createdTime.split("-")[2];
            }
            let dateA = new Date(this.correctDateFormat(a.createdTime));
            let dateB = new Date(this.correctDateFormat(b.createdTime));
            if (dateA < dateB) {
              return 1;
            }
            if (dateA > dateB) {
              return -1;
            }
            return 0;
          });
        }
        // Customer nuber
        if (this.sorted == "incCustomerNumber") {
          return archiveItems.sort((a, b) => {
            if (a.objektum_azonosito > b.objektum_azonosito) {
              return 1;
            }
            if (a.objektum_azonosito < b.objektum_azonosito) {
              return -1;
            }
            return 0;
          });
        } else if (this.sorted == "decCustomerNumber") {
          return archiveItems.sort((a, b) => {
            if (a.objektum_azonosito < b.objektum_azonosito) {
              return 1;
            }
            if (a.objektum_azonosito > b.objektum_azonosito) {
              return -1;
            }
            return 0;
          });
        }
      } else {
        return;
      }
    },
  },
};
</script>
<style>
.card-container {
  margin-bottom: 100px
  ;
}
.searchbar {
  justify-content: center;
  align-content: center;
  width: 200px;
}
</style>
