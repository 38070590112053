import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAdmin: false,
    user: null,
    user_id: "0000",
    userEmail: null,
    username: null,
    hivatkozasok: [
      "Elfogadott árajánlat",
      "Hibalista",
      "Ügyeleti hívás",
      "Ügyfél megkeresése",
      "Egyedi",
      "Egyéb",
    ],
    charlen: 0,
    // munka_tipusok: ["Távelügyelet felmérés", "Telepítési felmérés", "Távfelügyelet bekötés", "Akciós/kis telepítés", "Telepítés", "Átjelző javítás", "Javítás, karbantartás", "Átalány karbantartás", "Leszerelés, kikötés", "Behajtás", "Garanciális", "Ügyeleti javítás Budapest", "Ügyeleti javítás vidék"],
    munka_tipusok: null,
    fizetes_tipusok: ["Készpénz", "Átutalás", "Fix összegű utalás", "Díjtalan"],
    jogosultsag: ["Általános", "Admin"],
    telepito_ceg: [
      "Rimi Monitoring Kft.",
      "R.I.M.I. Magyarország Kft.",
      "SNI Hungary Kft.",
    ],
    szamla_kuldes_tipusok: ["Korábbi szerint", "E-mail", "Posta"],
    email_to: null,
    email_from: null,
    email_subject: null,
    szerelo_telefon: null,
    images: [],
    all_items: [],
    all_items_bool: false,
    functionButtonsHeight: 90,
    functionButtonsHeightMobile: 110,
  },

  getters: {
    getAdminState: (state) => {
      return state.isAdmin;
    },
    getUser: (state) => {
      return state.user;
    },
    getImage: (state) => (id) => {
      return state.images[state.images.findIndex((item) => item.id === id)]
        .image;
    },
  },

  mutations: {
    cacheImage(state, { id, data }) {
      state.images.push({ id: id, image: data });
    },
    setAdminState(state, bool) {
      state.isAdmin = bool;
    },
    setUser(state, data) {
      state.user = data;
    },
    setUserEmail(state, data) {
      state.userEmail = data;
    },
    setWorkerPhone(state, data) {
      state.szerelo_telefon = data;
    },
    setUsername(state, data) {
      state.username = data;
    },
    setEmailTo(state, data) {
      state.email_to = data;
    },
    setEmailFrom(state, data) {
      state.email_from = data;
    },
    setEmailSubject(state, data) {
      state.email_subject = data;
    },
    setJobTypes(state, data) {
      state.munka_tipusok = data;
    },
    setAllItems(state, data) {
      state.all_items = data;
    },
    changeItem(state, { index, data }) {
      state.all_items[index] = data;
    },
    all_items_data(state, data) {
      state.all_items_data = data;
    },
  },

  actions: {},
});
