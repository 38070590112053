import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import firebase from 'firebase'
import db from '@/firebase/init'
import store from '@/store/store'
import VueEllipseProgress from "vue-ellipse-progress";
import { VueMaskDirective } from 'v-mask'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueResizeObserver from "vue-resize-observer";

Vue.directive('mask', VueMaskDirective);
var filter = function(text, length, clamp){
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);
let app = null;

Vue.use(VueEllipseProgress);
Vue.use(VueReCaptcha, {
  siteKey: '6LfNehsbAAAAACrzBTZbXEDIOJOcfT3RgJjU1jIQ',
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true
  }
})
Vue.use(VueResizeObserver);





//wait for firebase auth to init before creating the app
firebase.auth().onAuthStateChanged((user) => {
  if(user){
    store.commit("setUser", user)
    db.collection('users').doc(user.email).get().then(doc =>{
      store.commit("setAdminState", doc.data().admin)
    })
    db.collection('users').doc(user.email).get().then(doc =>{
      store.commit("setUsername", doc.data().name)
    })
    db.collection('config').doc('email').get().then(doc =>{
      store.commit("setEmailTo", doc.data().to)
    })
    db.collection('config').doc('email').get().then(doc =>{
      store.commit("setEmailFrom", doc.data().from)
    })
    db.collection('config').doc('email').get().then(doc =>{
      store.commit("setEmailSubject", doc.data().subject)
    })

} else{
    // store.commit("setUser", null)
    store.commit("setAdminState", false)
}


if (!app) {
  app = new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}
})
