<template>
  <div class="login container">
    <v-snackbar v-model="login_failed" :timeout="3000">
      Sikertelen bejelentkezés :(
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="login_failed = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
    <v-card>
      <v-form ref="form" v-model="valid" @submit.prevent="login">
        <h2 align="center" class="blue--text mt-n2 pb-8 pt-3">Bejelentkezés</h2>

        <!-- E-MAIL -->
        <v-text-field
          v-model="email"
          label="E-mail"
          required
          :rules="emailRules"
          solo
          class="px-8 py-3"
        ></v-text-field>

        <!-- JELSZÓ -->
        <v-text-field
          v-model="password"
          label="Jelszó"
          required
          @click:append="showPassword = !showPassword"
          :rules="passwordRules"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          solo
          class="px-8 py-3"
        ></v-text-field>

        <!-- Login button -->
        <div class="pb-4" align="center">
          <v-btn
            @click.prevent="login"
            type="submit"
            v-if="isLoading == false"
            v-ripple
            :disabled="!valid"
            >Bejelentkezés</v-btn
          >
          <vue-ellipse-progress
            v-if="isLoading"
            :progress="progress"
            :size="30"
            :thickness="2"
            :loading="true"
            :noData="false"
          >
          </vue-ellipse-progress>
        </div>
        <!-- Feedback -->
        <h4 align="center" class="mb-3 red--text" v-if="feedback">
          {{ feedback }}
        </h4>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import firebase from "firebase";
import db from "@/firebase/init";
import InvisibleRecaptcha from "vue-invisible-recaptcha";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      valid: true,
      showPassword: false,
      reCaptcha: false,
      feedback: "",
      emailRules: [
        (v) => !!v || "A mező kitöltése kötelező",
        (v) => /.+@.+\..+/.test(v) || "Helytelen formátum",
      ],
      passwordRules: [
        (v) => !!v || "A mező kitöltése kötelező",
        (v) =>
          (v && v.length >= 8) ||
          "A jelszónak legalább 8 karakternek kell lennie",
      ],
      isLoading: false,
      progress: 0,
      login_failed: false,
    };
  },
  components: {
    "invisible-recaptcha": InvisibleRecaptcha,
  },
  methods: {
    login() {
      if (this.email && this.password) {
        this.isLoading = true;
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then((cred) => {
            db.collection("users")
              .doc(this.email)
              .get()
              .then((doc) => {
                this.$store.commit("setAdminState", doc.data().admin);
                this.$store.commit("setUser", doc.data());
                this.$store.state.user_id = doc.data().user_id;
                // this.$store.state.user = doc.data()
                // this.$store.commit("setUserEmail", this.email)
                this.$store.commit("setWorkerPhone", doc.data().phone_number);
                this.$router.push({
                  name: "HomePage",
                  params: { admin: doc.data().admin, id: doc.data().user_id },
                });
              });
          })
          .catch((err) => {
            this.feedback = err.message;
            this.isLoading = false;
            this.login_failed = true;
          }),
          (this.feedback = null);
      } else if (!this.reCaptcha) {
        this.feedback = "reCaptcha hitelesítés sikertelen!";
      }
    },
    validate() {
      this.isLoading = true;
    },
  },
  mounted() {
    this.$store.commit("setUser", null);
    this.$store.commit("setAdminState", false);
    this.$store.commit("setAllItems", []);
    this.valid = false;
  },
};
</script>

<style>
.login {
  max-width: 400px;
  margin-top: 60px;
}
.login h2 {
  font-size: 2.4em;
}
.login .field {
  margin-bottom: 16px;
}
</style>
