<template>
    <div>
      <v-card color="green darken-3" class="mb-8">
        <v-row align="center" justify="center">
          <v-col>
            <v-text-field :disabled="!$store.state.user.admin && archive == 'true'" v-model="anyag_megnevezes" label="Anyag megnevezése" v-mask="'XXXXXXXXXXXXXXXXXXXXXXX'" type="text" @change="() => _updateProd('updateProd', prodID, 'productType', anyag_megnevezes)" dense solo single-line class="mx-4 mb-n7" />
            <v-text-field :disabled="!$store.state.user.admin && archive == 'true'" v-model="anyag_darabszam" v-mask="'?###'" label="Darab" @change="() => _updateProd('updateProd', prodID, 'productNum', anyag_darabszam)" dense solo single-line class="mx-4 mt-5 mb-n7" />
            <v-text-field :disabled="!$store.state.user.admin && archive == 'true'" v-model="anyag_ar" v-mask="'######'" label="Ár/darab" @change="() => _updateProd('updateProd', prodID, 'productPrice', anyag_ar)" dense solo single-line class="mx-4 mt-5 mb-n7" />
            <v-row align="center" justify="center" class="mt-2 mb-2">
              <v-btn :disabled="!$store.state.user.admin && archive == 'true'" depressed color="grey lighten-3" @click.prevent="_deleteProd" class="mb-n4">
                Törlés
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </div>
</template>

<script>
export default {
    name: 'UploadProductCard',
    props: ['prodID', 'productType', 'productNum', 'productPrice', 'archive'],

  data(){
	return{
        anyag_megnevezes: this.productType,
        anyag_darabszam: this.productNum,
        anyag_ar: this.productPrice,
        discount: 0,
    }
  },
  methods: {
      _updateProd(func, prodID, key, data){
          this.$emit(func, {'prodID':prodID, 'key': key, 'data': data})
        //   if(key == "prodType") {
        //       this.munka_tipus = data
        //       if(this.hivatkozas != "Egyedi"){
        //         this.munka_tipusok.forEach((job, index) => {
        //           if(job == data){
        //               this.$emit("updateJob", {'jobID': jobID, 'key':"jobPrice", 'data': parseInt(this.munka_arak[index])})
        //               this.munka_ar = this.munka_arak[index]
        //           }
        //       }) 
        //       }
        //       else if(this.hivatkozas == "Egyedi") {
        //         // this.$emit("updateJob", {'jobID': jobID, 'key':"jobPrice", 'data': this.munka_ar})
        //       }

        //   }
        //   else if(key == "jobRef") {
        //       this.hivatkozas = data
        //   }
      },
      _updateProdPrice(){
        //Check if price is null
        if(!this.anyag_ar){
            this.anyag_ar = 0
              }
        this.$emit("updateProd", {'prodID': this.prodID, 'key':"productPrice", 'data': parseInt(this.anyag_ar)})
      },
        _updateProdNum(){
        //Check if num is null
        if(!this.anyag_darabszam){
            this.anyag_darabszam = 0
              }
        this.$emit("updateProd", {'prodID': this.prodID, 'key':"productNum", 'data': parseInt(this.anyag_darabszam)})
      },
      _deleteProd(){
        this.$emit("deleteProd", this.prodID)
      },
      
  },
    watch: {
    anyag_ar: function(){
      if(!this.anyag_ar){
        this.anyag_ar = 0
      }
    },
    anyag_darabszam: function(darab){
      if(this.anyag_darabszam.toString().charAt(0) != '-' && this.anyag_darabszam.toString().charAt(0) != '1' && this.anyag_darabszam.toString().charAt(0) != '2' && this.anyag_darabszam.toString().charAt(0) != '3' && this.anyag_darabszam.toString().charAt(0) != '4' && this.anyag_darabszam.toString().charAt(0) != '5' && this.anyag_darabszam.toString().charAt(0) != '6' && this.anyag_darabszam.toString().charAt(0) != '7' && this.anyag_darabszam.toString().charAt(0) != '8' && this.anyag_darabszam.toString().charAt(0) != '9') {
        this.anyag_darabszam = 0
      }
    }
  },

}
</script>
<style>

</style>