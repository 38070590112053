import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '@/components/home/HomePage'
import ArchivePage from '@/components/home/ArchivePage'
import Signup from '@/components/auth/Signup'
import Login from '@/components/auth/Login'
import store from '@/store/store'
import Upload from '@/components/home/Upload'
import EditCard from '@/components/home/EditCard'
import AcceptJob from '@/components/validate/AcceptJob'
import firebase from "firebase"

Vue.use(VueRouter)
var guest = false

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    props: true
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    // async beforeEnter(to, from, next) {
    //   try {
    //     var hasPermission = await store.dispatch("getAdminState");
    //     if (hasPermission) {
    //       next()
    //     }
    //   } catch (e) {
    //     if(store.state.user != null){
    //       next({
    //         name: "HomePage"
    //       })
    //     }
    //     else {
    //       next({
    //         name: "Login" 
    //       })
    //     }
        
    //   }
    // } 
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/archivePage',
    name: 'ArchivePage',
    component: ArchivePage
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload
  },
{
  path: '/editcard',
  name: 'EditCard',
  component: EditCard,
},
{
  path: '/approve',
  name: 'AcceptJob',
  component: AcceptJob,
  props: route => ({ id: route.query.id })
}


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//Route guards
router.beforeEach((to, from, next) => {
  if(to.path == "/approve") {
    guest = true
  }
  if(to.path != "/login" && (store.state.user == null && guest == false)) {
    next({ name: 'Login'});
  }
  else if(to.path == "/Signup" && store.state.isAdmin != true) {
    next({ name: 'HomePage'});
  }
  // else if(to.path == "/ArchivePage" && store.state.isAdmin != true) {
  //   next({ name: 'HomePage'});
  // }

  else {
    next()
  }
})

export default router
